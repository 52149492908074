import React, {useState, useMemo, useLayoutEffect} from 'react'
import {useTheme} from '@mui/material/styles'
import styled from '@emotion/styled'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import CalendarMonth from '@mui/icons-material/CalendarMonth'
import Popover from '@mui/material/Popover'
import dayjs from 'dayjs'
import {DateCalendar, DigitalClock} from '@mui/x-date-pickers'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'

interface IComponent {
    value: string | null,
    title: string
    name: string,
    datetime: boolean,
    min?: string,
    max?: string,
    disabled: boolean,
    onChange: (name: string, value: string | null) => void
}

const DateTime = (props: IComponent) => {
    const [open, setOpen] = useState<Element | null>(null)
    const format = props.datetime ? 'DD MMM YYYY HH:mm' : 'DD MMM YYYY'
    
    const handleClick = (e: React.MouseEvent) => {
        setOpen(e.currentTarget)
    }

    const handleClose = () => {
        setOpen(null)
    }

    const handleChange = (value: dayjs.Dayjs | null) => {
        props.onChange(props.name, dayjs(value).toISOString())
    }

    const date = useMemo(() => {
        return props.value ? dayjs(props.value).format(format) : ''
    }, [props.value])

    return (
        <React.Fragment>
            <TextField
                disabled={props.disabled}
                value={date}
                InputLabelProps={{ shrink: true }}
                label={props.title}
                name={props.name}
                variant="outlined"
                fullWidth
                onClick={handleClick}
                InputProps={{
                    readOnly: true,
                    endAdornment: 
                        <InputAdornment position="end">
                            <CalendarMonth />
                        </InputAdornment>
                }}
            />
            <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                slotProps={{
                    paper: {
                        sx: {
                            display: 'flex'
                        }
                    }
                }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                        value={props.value ? dayjs(props.value) : null}
                        onChange={handleChange}
                        minDate={props.min ? dayjs(props.min) : undefined}
                        maxDate={props.max ? dayjs(props.max) : undefined}
                    />
                    {props.datetime &&
                        <DigitalClock value={props.value ? dayjs(props.value) : null} onChange={handleChange} ampm={false} />
                    }
                </LocalizationProvider>
            </Popover>
        </React.Fragment>
    )
}

export default React.memo(DateTime)
