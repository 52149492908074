import { AutoFixHigh, CalendarMonth} from '@mui/icons-material';
import Card, { types } from 'components/Card';

import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import TaskAlt from '@mui/icons-material/TaskAlt';
import EditForm from 'components/EditForm';
import Person from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import StarsIcon from '@mui/icons-material/Stars';
import { useNavigate } from 'react-router-dom';
import routes from 'enums/routes';

import { formConfig } from './formConfig';
import { Row } from './row';

const icon = {
  fontSize: 18
}

const TOPBAR_HEIGHT = 64

const config ={
  id: 'id',
  title: 'name',
  fields: [
    {
      label: 'Team Member',
      icon: <Person sx={icon}/>,
      type: types.Chip,
      value: 'teamMember'
    },
    {
      label: 'Manager',
      icon: <Person sx={icon}/>,
      type: types.Chip,
      value: 'manager'
    },
    {
      label: 'Role',
      icon: <AutoFixHigh sx={icon}/>,
      type: types.Chip,
      value: 'role'
    },
    {
      label: 'Department',
      icon: <BusinessIcon sx={icon}/>,
      type: types.Chip,
      value: 'department'
    },
    {
      label: 'Start Date',
      icon: <CalendarMonth sx={icon}/>,
      type: types.Date,
      value: 'start_date'
    },
    {
      label: 'Level',
      icon: <StarsIcon sx={icon}/>,
      type: types.Chip,
      value: 'level'
    },
  ]
}

const TeamMembersOverview = () => {

  const navigate = useNavigate()

  const onClick = useCallback((id: number) => {
      navigate(`${routes.Member}/1/${routes.PerformanceEvaluation}`)
  }, [])
  
  const project = Row[0];

  const [openForm, setOpenForm] = useState<boolean>(false)
  const [openPopup, setOpenPopup] = useState<boolean>(false)

  const handleFormOpen = () => {
      setOpenForm(true)
  }

  const handleFormClose = () => {
    setOpenForm(false)
}

const handleConfirm = () => {
    handleFormClose()
}

const handlePopupOpen = () => {
    setOpenPopup(true)
}

const handlePopupClose = () => {
    setOpenPopup(false)
}

const handleDelete = () => {
    handlePopupClose()
}

  return (
    <React.Fragment>
        <Box style ={{display: 'flex', height: TOPBAR_HEIGHT}}>
          <Breadcrumbs texts={['Team Members', 'Overview']}/>
            <Box style={{margin:'18px', marginLeft: 'auto'}}>
            <Chip
                            icon={<TaskAlt sx={icon} />}
                            label={'Change Team Member'}
                            sx={{
                                cursor: 'pointer',
                                width: '150px'
                            }}
                            onClick={handleFormOpen}
                        />
            <Chip 
                            icon={<AutoFixHigh sx={icon} />}
                            label={'Delete Team Member'}
                            sx={{
                                cursor:'pointer',
                                width:'150px'
                            }}
                            onClick={handlePopupOpen}
                        />

                </Box>
            </Box>
                         {project ? (
       <div> 
        <Card config={config} data={project} presentation onClick={onClick}/>
        </div>
      ) : (
        <div>No project data available.</div>
      )}
 <EditForm
        open={openForm}
        handleClose={handleFormClose}
        handleConfirm={handleConfirm}
        title={'Change project'}
        config={formConfig} initialValues={{}}                     
              />

<Dialog
                open={openPopup}
                onClose={handlePopupClose}
            >
                <DialogTitle>
                    Confirm your action
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete {project && project.employee}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePopupClose}>Cancel</Button>
                    <Button onClick={handleDelete} variant='contained'>Confirm</Button>
                </DialogActions>
            </Dialog>
       
    </React.Fragment>
  );
};

export default TeamMembersOverview;
