import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import WorkIcon from '@mui/icons-material/Work';
import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import InfoIcon from '@mui/icons-material/Info';
import suppliers from 'data/suppliers';
import { randomNumber } from '_protolib/Randomizer';

export default function CheckboxList() {
  const [checked, setChecked] = React.useState([0]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

//   suppliers.forEach((s: any, id: number) => Object.assign(s, {
//     id: id + 1,
//     rank: randomNumber(50, 99) + (randomNumber(1, 9) * 0.1),
//     pastProjects: randomNumber(1, 4)
//   }))

//   console.log(suppliers)

  return (
    <List sx={{ width: 500, bgcolor: 'background.paper' }}>
      {suppliers.map((supplier: any) => {
        const labelId = `checkbox-list-label-${supplier.id}`;

        return (
          <ListItem
            key={supplier.id}
            secondaryAction={
              <IconButton edge="end" aria-label="comments">
                <InfoIcon />
              </IconButton>
            }
            disablePadding
          >
            <ListItemButton role={undefined} onClick={handleToggle(supplier)} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(supplier) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
        <ListItemAvatar>
          <Avatar src="https://seeklogo.com/images/H/hilton-hotels-resorts-logo-9B26B29074-seeklogo.com.png">
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={supplier.name} secondary={(
          <React.Fragment>
            <span>Rank: {supplier.rank}</span>
            <span style={{ marginLeft: 25 }}>Track record: {supplier.rank}</span>
          </React.Fragment>
        )} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}