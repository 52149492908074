import {
    MockFirstNameAll, MockFirstNameFemale, MockFirstNameMale, MockLastName
} from "./mock/persons";
import { MockNumber } from "./mock/numbers";
import { MockLoremIpsum } from "./mock/lorem-ipsum";
import { MockEmailDomain, MockEmailSeparator } from "./mock/email";
import { MockChars } from "./mock/chars";

export const randomNumber = (min: number, max: number): number => {
    return min + Math.floor(Math.random() * (max - min + 1));
}

export const random = <T>(array: T[]): T => {
    return array[randomNumber(0, array.length - 1)];
}

export const randomChance = (chance: number = 1): boolean => {
    return !!randomNumber(0, chance)
}

export const randomDateIso = (min: string, max: string): string => {
    const mindate: number = new Date(min).getTime();
    const maxdate: number = new Date(max).getTime();
    const date = new Date(mindate + Math.random() * (maxdate - mindate));
    return `${date.toISOString().slice(0, 10)}T00:00:00.999`;
}

export const randomDate = (min: string, max: string): Date => {
    return new Date(randomDateIso(min, max));
}

export const setRandomTime = (date: Date): Date => {
    const result: Date = new Date(date);
    result.setHours(randomNumber(8, 19));
    result.setMinutes(randomNumber(0, 59));
    result.setSeconds(randomNumber(0, 59));
    result.setMilliseconds(randomNumber(0, 999));
    return result;
}

export function randomString(min: number, max: number, charsOnly: boolean = false): string {
    const chars: string[] = [...MockChars.split(''), ...charsOnly ? [] : MockNumber.split('')];
    const length: number = randomNumber(min, max);
    let result: string = '';
    for (let i = 0; i < length; i++) {
        result += random(chars);
    }
    return result;
}

export const getRandomDescription = (): string => {
    const result: string[] = [];
    const length: number = randomNumber(3, 8);
    for (let i = 0; i < length; i++) {
        result.push(random(MockLoremIpsum));
    }
    return result.join('\n');
}

export function getRandomPrice(): number {
    return Number((randomNumber(2, 95) + (randomNumber(1, 99) * 0.01)).toFixed(2));
}

export function getRandomPersonName(gender: string = ''): string {
    let firstName: string = '';
    if (gender === 'Male') {
        firstName = random(MockFirstNameMale);
    } else if (gender === 'Female') {
        firstName = random(MockFirstNameFemale);
    } else {
        firstName = random(MockFirstNameAll);
    }
    return `${firstName} ${random(MockLastName)}`;
}

export function getRandomPersonEmail(name: string, date: string = ''): string {
    const parts: string[] = name.toLowerCase().split(' ');
    const separator: string = random(MockEmailSeparator);
    const first: number = random([0, 1]);
    const last: number = first ? 0 : 1;
    if (random([0, 1])) {
        parts[0] = parts[0][0];
    }
    if (random([0, 1]) && date) {
        parts[last] += (separator + ((new Date(date)).getFullYear()).toString().slice(-2));
    }
    return parts[first] + separator + parts[last] + '@' + random(MockEmailDomain);
}

export function getRandomPhone(code: string, length: number): string {
    return `+${code}${(new Array(length).fill(1)).map(() => randomNumber(0, 9)).join('')}`
}