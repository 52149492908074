import React from "react";
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import Root from "containers/Root";
import Container from "containers/Container";
import Auth from "containers/Auth";
import Projects from "containers/Projects";
import Opportunities from "containers/Opportunities";
import Signin from "containers/Signin";
import Profit from "containers/Project/Profit";
import Program from "containers/Project/Program";
import routes from "enums/routes";
import { store } from "./app/store";
import theme from "theme";
import Suppliers from "containers/Suppliers";
import TeamMembers from "containers/TeamMembers";
import SiteInspections from "containers/Project/SiteInspections";
import RiskAssessment from "containers/Project/RiskAssessment";
import Invoices from "containers/Project/Invoices";
import Balance from "containers/Project/Balance";
import Actions from "containers/Project/Actions";
import ProfitLoss from "containers/Project/Profit";
import ProjectsList from "containers/ProjectsList";
import VoqinWay from "containers/VoqinWay";
import ProjectHistory from "containers/ProjectHistory";
import ProjectGhant from "containers/Project/Ghant";
import Overview from "containers/Project/Overview";
import TeamMembersOverview from "containers/TeamMembers/overview";
import EvaluationPerformance from "containers/TeamMembers/evalutationIndex";
import Procurement from "containers/Procurement";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />}>
      <Route path={`${routes.Signin}`} element={<Signin />} />
      <Route element={<Auth />}>
        <Route element={<Container />}>
          <Route path={`${routes.Projects}`} element={<ProjectsList />} />
          <Route path={`${routes.Opportunities}`} element={<Opportunities />} />
          <Route path={`${routes.Suppliers}`} element={<Suppliers />} />
          <Route path={`${routes.Members}`} element={<TeamMembers />} />
          <Route path={`${routes.Member}/:memberId`}>
            <Route path={routes.TeamMembersOverview} element={<TeamMembersOverview />} />
            <Route path={routes.PerformanceEvaluation} element={<EvaluationPerformance/>} />
          </Route>
          <Route path={`${routes.VoqinWay}`} element={<VoqinWay />} />
          <Route path={`${routes.Procurement}`} element={<Procurement />} />
          <Route path={`${routes.Project}/:projectId`}>
            <Route path={routes.Overview} element={<Overview />} />
            <Route path={routes.Profit} element={<ProfitLoss projectId={1} />} />
            <Route path={routes.Program} element={<Program />} />
            <Route path={routes.ProjectHistory} element={<ProjectHistory />} />
            <Route path={routes.ProjectGhant} element={<ProjectGhant />} />
            <Route
              path={routes.SiteInspections}
              element={<SiteInspections />}
            />
            <Route path={routes.RiskAssessment} element={<RiskAssessment />} />
            <Route path={routes.Invoices} element={<Invoices />} />
            <Route path={routes.Balance} element={<Balance />} />
            <Route path={routes.Actions} element={<Actions />} />
          </Route>
        </Route>
      </Route>
      <Route
        path="*"
        element={<Navigate to={`${routes.Projects}`} replace={true} />}
      />
    </Route>
  )
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
