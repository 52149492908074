import React, { useEffect } from "react";
import {
  Outlet,
  Link,
  useLocation,
  useParams,
  NavLink,
  useResolvedPath,
} from "react-router-dom";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BarChart from "@mui/icons-material/BarChart";
import Insights from "@mui/icons-material/Insights";
import Logout from "@mui/icons-material/Logout";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getLogout } from "slices/auth";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import routes from "enums/routes";

const main = [
  {
    title: "Projects",
    path: routes.Projects,
  },
  {
    title: "Opportunities",
    path: routes.Opportunities,
  },
  {
    title: "Suppliers",
    path: routes.Suppliers,
  },
  {
    title: "Team members",
    path: routes.Members,
  },
  {
    title: "Voqin Way",
    path: routes.VoqinWay,
  },
  {
    title: "Procurement",
    path: routes.Procurement,
  },
];

const projectRoutes = [
  {
    title: "Overview",
    path: routes.Overview,
  },
  {
    title: "Profit & Loss",
    path: routes.Profit,
  },
  {
    title: "Program",
    path: routes.Program,
  },
  {
    title: "History",
    path: routes.ProjectHistory,
  },
  {
    title: "Gantt",
    path: routes.ProjectGhant,
  },
  {
    title: "Site Inspections",
    path: routes.SiteInspections,
  },
  {
    title: "Risk Assessment",
    path: routes.RiskAssessment,
  },
  {
    title: "Invoices",
    path: routes.Invoices,
  },
  {
    title: "Balance",
    path: routes.Balance,
  },
  {
    title: "Actions",
    path: routes.Actions,
  },
];

const Container = () => {
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { projectId } = useParams();

  const onClick = () => {
    dispatch(getLogout());
  };

  return (
    <React.Fragment>
      <Drawer
        sx={{
          width: 250,
        }}
        PaperProps={{
          sx: {
            width: 250,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        {projectId && (
          <React.Fragment>
            <List>
              {projectRoutes.map((item, index) => {
                const to = `${routes.Project}/${projectId}/${item.path}`;

                return (
                  <ListItem key={item.path} disablePadding>
                    <ListItemButton
                      component={Link}
                      to={to}
                      selected={to === pathname}
                    >
                      <ListItemIcon>
                        {index % 2 === 0 ? <BarChart /> : <Insights />}
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            <Divider />
            <ListItem key={23452345} disablePadding>
              <ListItemButton
                component={Link}
                to={routes.Projects}
              >
                <ListItemIcon>
                  <ArrowBackIosIcon/>
                </ListItemIcon>
                <ListItemText primary="All Projects" />
              </ListItemButton>
            </ListItem>
          </React.Fragment>
        )}
        {!projectId && (<List>
          {main.map((item, index) => (
            <ListItem key={item.path} disablePadding>
              <ListItemButton
                component={Link}
                to={item.path}
                selected={pathname === item.path}
              >
                <ListItemIcon>
                  {index % 2 === 0 ? <BarChart /> : <Insights />}
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        )}
      </Drawer>
      <AppBar position="fixed" sx={{ zIndex: 1300 }}>
        <Toolbar>
          <Typography component="div" sx={{ flexGrow: 1 }}></Typography>
          <Tooltip title="Logout" placement="bottom">
            <IconButton
              color="info"
              onClick={onClick}
              sx={{ marginLeft: "auto" }}
            >
              <Logout />
            </IconButton>
          </Tooltip>
          <Tooltip title={user && user.name} placement="bottom">
            <IconButton size="large" color="info">
              <AccountCircle />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Box
        sx={{ flexGrow: 1, marginTop: "64px", minWidth: 0, minHeight: 0 }}
        component="main"
      >
        <Outlet />
      </Box>
    </React.Fragment>
  );
};

export default Container;
