import React from 'react'
import TextField from '@mui/material/TextField'

interface IComponent {
    value: string,
    title: string,
    name: string,
    multiline: boolean,
    disabled: boolean,
    onChange: (name: string, value: string) => void
}

const Text = (props: IComponent) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(props.name, e.target.value)
    }

    return (
        <TextField
            disabled={props.disabled}
            value={props.value}
            InputLabelProps={{ shrink: true }}
            label={props.title}
            name={props.name}
            variant="outlined"
            onChange={onChange}
            fullWidth
            multiline={props.multiline}
            minRows={props.multiline ? 3 : undefined}
        />
    )
}

export default React.memo(Text)
