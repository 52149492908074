import {useEffect, useRef} from 'react'
import {Outlet} from 'react-router-dom'
import styled from '@emotion/styled'
import LinearProgress from '@mui/material/LinearProgress'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getInit} from 'slices/auth'
import {messageReset} from 'slices/message'


const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
`

const Root = () => {
    const init = useRef<boolean>(false)

    const text = useAppSelector(state => state.message.text)
    const variant = useAppSelector(state => state.message.variant)
    const open = useAppSelector(state => state.message.open)

    const auth = useAppSelector(state => state.auth.pending)
    const project = useAppSelector(state => state.project.pending)

    const dispatch = useAppDispatch()

    if (!init.current) {
        init.current = true
        dispatch(getInit())
    }

    const onClose = (e: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }

        dispatch(messageReset())
    }

    const pending = auth || project
    
    return (
        <Container>
            {pending && <LinearProgress color='warning' sx={{position: 'absolute', width: '100%', zIndex: 1400}} />}
            <Outlet />
            <Snackbar open={open} autoHideDuration={3000} onClose={onClose}>
                <Alert variant='filled' severity={variant} sx={{ width: '100%' }}>
                    {text}
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default Root