import { types } from 'enums/fields'

export const formConfig = [
  {
    title: "Employee",
    type: types.Text,
    name: "employee",
    required: true
  },
  {
    title: "Manager",
    type: types.Text,
    name: "manager",
    required: true
  },
  {
    title: "Role",
    type: types.Text,
    name: "role",
    required: true
  },
  {
    title: "Department",
    type: types.Text,
    name: "department",
    required: true
  },
  {
    title: "Start date",
    type: types.Date,
    name: "start_date",
    required: true
  },
  {
    title: "Level",
    type: types.Text,
    name: "level",
    required: true
  }
];

export const FilterStatusItems = ['Manager', 'Role']

export const FilterTypeItems = ['Department', 'Level']