export const EvaluationFilters = ['Department', 'Manager', 'Level']

export const EvaluationStatus = ['']


export const EvaluationData = [
    {
    "memberId":7,
    "teamMember":"Marc Rees",
    "manager":"William Jones",
    "role":"COO",
    "department":"Projects Department",
    "review_score":"4,8",
    "prior_score":"4",
    "change":"20%"
    },
    {
    "memberId":6,
    "teamMember":"Alvin Silva",
    "manager":"Marc Ress",
    "role":"Project Manager",
    "department":"Projects Department",
    "review_score":"3,8",
    "prior_score":"4,2",
    "change":"-10%"
    },
    {
    "memberId":8,
    "teamMember":"Pat Heather",
    "manager":"Marc Rees",
    "role":"Project Coordinator",
    "department":"Projects Department",
    "review_score":"3,9",
    "prior_score":"",
    "change":""
    },
{
    "memberId":9,
    "teamMember":"Mike Siri",
    "manager":"Pat Heather",
    "role":"Team Leader",
    "department":"Projects Department",
    "review_score":"4,8",
    "prior_score":"4,7",
    "change":"2%"
},
{
    "memberId":10,
    "teamMember":"Kay Star",
    "manager":"Mike Siri",
    "role":"Team Member",
    "department":"Projects Department",
    "review_score":"3,5",
    "prior_score":"3,6",
    "change":"-3%"
},
{
    "memberId":13,
    "teamMember":"Marc Foster",
    "manager":"Mike Siri",
    "role":"Team Memeber",
    "department":"Projects Department",
    "review_score":"4,0",
    "prior_score":"3,5",
    "change":"14%"
}
]
