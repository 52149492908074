import { Box, Chip, Tooltip } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { renderDate, renderDateOnly, renderMoney, renderPercent } from "../../oldcomponents/utils";
import { SupplierStatus } from "api/enums";
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import React from "react";

const renderTimes = (value: any) => {
    if (value && typeof value === 'number') {
        if (value === 1) {
            return `${value} time`
        } else {
            return `${value} times`
        }
    } else {
        return '-'
    }
}

const getValueString = (value: any, column: any, row?: any) => {
    switch (column.type) {
        case 'date':
            return renderDate(value)
        case 'dateonly':
            return renderDateOnly(value)
        case 'money':
            return renderMoney(value)
        case 'times':
            return renderTimes(value)
        case 'percent':
            return renderPercent(value)
        case 'unit':
            return value
    }
    if (value === null || value === undefined) {
        if (column.type === 'itemName') { // todo - delete this
            return '-'
        }
        return ''
    } else if (typeof value === 'string') {
        return value
    } else if (value.toString) {
        return value.toString()
    } else {
        return '' + value
    }
}

const renderStatusIcon = (value: any, row: any): JSX.Element => {
    const onClick = (r: any) => {}
    switch (value) {
        case SupplierStatus.Approved:
            return (
                <Tooltip title="Approved by client">
                    <ThumbUpIcon color="success" style={{cursor: 'pointer'}} onClick={onClick.bind(this, row)} />
                </Tooltip>
            );
        case SupplierStatus.Rejected:
            return (
                <Tooltip title="Rejected by client">
                    <ThumbDownIcon color="error" style={{cursor: 'pointer'}} onClick={onClick.bind(this, row)} />
                </Tooltip>
            );
        case SupplierStatus.Awaiting:
            return (
                <Tooltip title="Awaiting for client approval">
                    <HourglassBottomIcon color="secondary" style={{cursor: 'pointer'}} onClick={onClick.bind(this, row)} />
                </Tooltip>
            );
        case SupplierStatus.Deadline:
            return (
            <Tooltip title="Deadline in 5 days, supplier is notified by email">
                <AccessAlarmIcon color="error" style={{cursor: 'pointer'}} onClick={onClick.bind(this, row)} />
            </Tooltip>
            );
        case 400:
            return (
                <Tooltip title="Quotation request">
                    <HelpCenterIcon color="secondary" style={{cursor: 'pointer'}} onClick={onClick.bind(this, row)} />
                </Tooltip>
            )
    }
    return (
        <Tooltip title="No status">
            <Box></Box>
        </Tooltip>
    );
}

const renderStatus = (value: any, row: any): JSX.Element => {
    return (
        <Box style={{ width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
            {renderStatusIcon(value, row)}
        </Box>
    );
}

const renderClient = (value: any, row: any): JSX.Element => {
    return (
        value &&
            <Box style={{ padding: '0 10px' }}>
                <Chip
                    label={value}
                    sx={{
                        cursor: 'pointer'
                    }}
                />
            </Box>
    );
}

const renderProjectStatus = (value: any, row: any): JSX.Element => {
    return (
        <Box style={{ padding: '0 10px' }}>
            <Chip
                label={value}
                sx={{
                    cursor: 'pointer',
                    backgroundColor: row.status_color || ''
                }}
            />
        </Box>
    )
}

const renderChip = (value: any, row: any): JSX.Element | null => {
    return (
        value ?
        <Box style={{ padding: '0 10px' }}>
            <Chip
                label={value}
                sx={{
                    cursor: 'pointer',
                    // backgroundColor: row.status_color || ''
                }}
            />
        </Box>
        : null
    )
}

const renderSupplier = (value: any, row: any): JSX.Element => {
    const onClick = (r: any) => {}
    return (
        <Box style={{ padding: '0 10px', overflow: 'auto', display: 'flex', alignItems: 'center' }}>
            <Box sx={{ marginRight: 1, }}>{value}</Box>
            {
                row.item_referenced && !!value && (
                    <Tooltip title="Referral supplier">
                        <AutoAwesomeIcon color="primary" style={{cursor: 'pointer'}} onClick={onClick.bind(this, row)} />
                    </Tooltip>
                )
            }
        </Box>
    );
}

const renderNameOrCategory = (value: any, row: any): JSX.Element => {
    return (
        <Box style={{ padding: '0 10px', fontWeight: row.category ? 'bold' : '', overflow: 'auto' }}>
            <Box style={{ whiteSpace: 'nowrap' }}>{row.category || value}</Box>
        </Box>
    )
}

const greenColor = 'rgb(52, 168, 83)'
const redColor = 'rgb(234, 68, 53)'

const renderConvSpeed = (value: any, row: any): JSX.Element => {
    const color = value < 30 ? greenColor : redColor
    return (
        <span style={{ color, padding: '0px 10px' }}>{value} days</span>
    )
}

const renderClientInter = (value: any, row: any): JSX.Element => {
    const color = value < 8 ? greenColor : redColor
    return (
        <span style={{ color, padding: '0px 10px' }}>{value}</span>
    )
}

const renderCell = (value: any, column: any, row?: any): JSX.Element | null => {
    switch (column.type) {
        case 'itemStatus':
            return renderStatus(value, row)
        case 'supplier':
            return renderSupplier(value, row)
        case 'client':
            return renderClient(value, row)
        case 'status':
            return renderProjectStatus(value, row)
        case 'convSpeed':
            return renderConvSpeed(value, row)
        case 'clientInter':
            return renderClientInter(value, row)
        case 'nameorcategory':
            return renderNameOrCategory(value, row)
        case 'chip':
            return renderChip(value, row)
    }
    return (
        <Box style={{ padding: '0 10px', overflow: 'auto' }}>
            <Box style={{ whiteSpace: 'nowrap' }}>{getValueString(value, column, row)}</Box>
        </Box>
    )
}

export default renderCell