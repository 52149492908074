export default [
    {
        "name": "Ritz",
        "id": 1,
        "rank": 94.3,
        "pastProjects": 1
    },
    {
        "name": "Marriott",
        "id": 2,
        "rank": 85.2,
        "pastProjects": 4
    },
    {
        "name": "Hilton",
        "id": 3,
        "rank": 56.9,
        "pastProjects": 3
    },
    {
        "name": "Hyatt",
        "id": 4,
        "rank": 84.6,
        "pastProjects": 2
    },
    {
        "name": "IHG",
        "id": 5,
        "rank": 60.5,
        "pastProjects": 2
    },
    {
        "name": "Four Seasons",
        "id": 6,
        "rank": 92.9,
        "pastProjects": 3
    },
    {
        "name": "Kimpton",
        "id": 7,
        "rank": 97.1,
        "pastProjects": 1
    },
    {
        "name": "Accor",
        "id": 8,
        "rank": 82.1,
        "pastProjects": 1
    },
    {
        "name": "Microsoft",
        "id": 9,
        "rank": 56.8,
        "pastProjects": 1
    },
    {
        "name": "Apple",
        "id": 10,
        "rank": 81.9,
        "pastProjects": 1
    },
    {
        "name": "Boston Dynamics",
        "id": 11,
        "rank": 84.5,
        "pastProjects": 3
    },
    {
        "name": "Space X",
        "id": 12,
        "rank": 62.1,
        "pastProjects": 3
    },
    {
        "name": "Open AI",
        "id": 13,
        "rank": 78.5,
        "pastProjects": 1
    },
    {
        "name": "Frame Connection",
        "id": 14,
        "rank": 55.4,
        "pastProjects": 1
    },
    {
        "name": "Transavia",
        "id": 15,
        "rank": 78.1,
        "pastProjects": 4
    },
    {
        "name": "Arturia",
        "id": 16,
        "rank": 95.3,
        "pastProjects": 3
    }
]