const MockProjectItems = [
    {
        "id": -111111,
        "category": "18 May - Transportation from Airport to Hotel",
        "background": "#f7f7f7"
    },
    {
        "suppliers": [
            {
                "item_id": 2,
                "item_name": "",
                "item_status": 300,
                "item_starts": "2023-05-18T14:30:02.000Z",
                "item_ends": "2023-05-18T15:00:00.000Z",
                "item_referenced": false,
                "item_participants": "",
                "item_unit": "",
                "item_gross": "80",
                "item_total": "80",
                "item_totalWTaxes": "80",
                "item_byingCost": "36",
                "item_profitPercent": "0.40",
                "item_supplierComissions": "0.1",
                "item_vat": 0.23,
                "item_netPayable": null,
                "item_totalPayable": null,
                "item_netBuyingCost": null,
                "item_totalCost": null,
                "item_profit": null,
                "item_profitMargin": null,
                "item_comissionsDeducted": null,
                "item_comissionsConsidered": null,
                "item_salesOffice": null,
                "item_comissionsClient": null,
                "item_invoiceId": null,
                "item_projectItemId": 1,
                "item_supplierId": 2,
                "item_supplier_id": 2,
                "item_supplier_supplierClassId": 2,
                "item_supplier_name": "Amiroad",
                "item_supplier_class_id": 2,
                "item_supplier_class_name": "",
                "id": 10002,
                "name": ""
            },
            {
                "item_id": 3,
                "item_name": "",
                "item_status": 400,
                "item_starts": "2023-05-18T14:30:11.000Z",
                "item_ends": "2023-05-18T15:00:00.000Z",
                "item_referenced": false,
                "item_participants": "",
                "item_unit": "",
                "item_gross": "",
                "item_total": "",
                "item_totalWTaxes": "",
                "item_byingCost": "",
                "item_profitPercent": null,
                "item_supplierComissions": null,
                "item_vat": null,
                "item_netPayable": null,
                "item_totalPayable": null,
                "item_netBuyingCost": null,
                "item_totalCost": null,
                "item_profit": null,
                "item_profitMargin": null,
                "item_comissionsDeducted": null,
                "item_comissionsConsidered": null,
                "item_salesOffice": null,
                "item_comissionsClient": null,
                "item_invoiceId": null,
                "item_projectItemId": 1,
                "item_supplierId": 3,
                "item_supplier_id": 3,
                "item_supplier_supplierClassId": 2,
                "item_supplier_name": "Bolt",
                "item_supplier_class_id": 2,
                "item_supplier_class_name": "",
                "id": 10003,
                "name": ""
            }
        ],
        "id": 1,
        "projectId": 1,
        "name": "Mercedes E-Class with professional driver at disposal",
        "programName": "18 May - Transportation from Airport to Hotel",
        "item_id": 1,
        "item_name": "",
        "item_status": 0,
        "item_starts": "2023-05-18T14:30:01.000Z",
        "item_ends": "2023-05-18T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 1,
        "item_unit": 1,
        "item_gross": "78",
        "item_total": "78",
        "item_totalWTaxes": "78",
        "item_byingCost": "38",
        "item_profitPercent": "0.4",
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": "46.74",
        "item_totalPayable": "46.74",
        "item_netBuyingCost": "46.74",
        "item_totalCost": "46.74",
        "item_profit": 44.858251835878754,
        "item_profitMargin": "0.4",
        "item_comissionsDeducted": "17.94",
        "item_comissionsConsidered": "60.06",
        "item_salesOffice": null,
        "item_comissionsClient": "Conference Direct",
        "item_invoiceId": null,
        "item_projectItemId": 1,
        "item_supplierId": 1,
        "item_supplier_id": 1,
        "item_supplier_supplierClassId": 1,
        "item_supplier_name": "",
        "item_supplier_class_id": 1,
        "item_supplier_class_name": "Transportation",
        "_count_suppliers": 2
    },
    {
        "suppliers": null,
        "id": 2,
        "projectId": 1,
        "name": "Bottled mineral waters in the vehicle (1 per person)",
        "programName": "18 May - Transportation from Airport to Hotel",
        "item_id": 4,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-18T14:31:01.000Z",
        "item_ends": "2023-05-18T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 2,
        "item_unit": 1,
        "item_gross": "180",
        "item_total": "360",
        "item_totalWTaxes": "360",
        "item_byingCost": 135.2770771037149,
        "item_profitPercent": 0.22543,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 44.7229228962851,
        "item_totalPayable": 44.7229228962851,
        "item_netBuyingCost": 44.7229228962851,
        "item_totalCost": 44.7229228962851,
        "item_profit": 39.769847753949854,
        "item_profitMargin": 0.22543,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 2,
        "item_supplierId": 4,
        "item_supplier_id": 4,
        "item_supplier_supplierClassId": 3,
        "item_supplier_name": "Luso",
        "item_supplier_class_id": 3,
        "item_supplier_class_name": "Travel",
        "_count_suppliers": 0
    },
    {
        "id": -222222,
        "category": "20 May - Private Visit to a Winery in Sintra",
        "background": "#f7f7f7"
    },
    {
        "suppliers": null,
        "id": 14,
        "projectId": 1,
        "name": "English speaking transportation staff to accompany the participants in the vehicle",
        "programName": "20 May - Private Visit to a Winery in Sintra",
        "item_id": 26,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-18T14:53:01.000Z",
        "item_ends": "2023-05-19T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 5,
        "item_unit": 1,
        "item_gross": "250",
        "item_total": "1250",
        "item_totalWTaxes": "1250",
        "item_byingCost": 213.88182587660475,
        "item_profitPercent": 0.20650682557442468,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 36.11817412339525,
        "item_totalPayable": 36.11817412339525,
        "item_netBuyingCost": 36.11817412339525,
        "item_totalCost": 36.11817412339525,
        "item_profit": 32.64847082253517,
        "item_profitMargin": 0.20650682557442468,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 14,
        "item_supplierId": 5,
        "item_supplier_id": 5,
        "item_supplier_supplierClassId": 4,
        "item_supplier_name": "Joana Melo Moreira",
        "item_supplier_class_id": 4,
        "item_supplier_class_name": "Stuff",
        "_count_suppliers": 0
    },
    {
        "suppliers": null,
        "id": 15,
        "projectId": 1,
        "name": "A unique wine experience and exclusive made by the family | includes wine tasting, tour around the property and lunch",
        "programName": "20 May - Private Visit to a Winery in Sintra",
        "item_id": 27,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-18T14:54:01.000Z",
        "item_ends": "2023-05-19T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 5,
        "item_unit": 1,
        "item_gross": "250",
        "item_total": "1250",
        "item_totalWTaxes": "1250",
        "item_byingCost": 206.53838211275976,
        "item_profitPercent": 0.263161122,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 43.46161788724024,
        "item_totalPayable": 43.46161788724024,
        "item_netBuyingCost": 43.46161788724024,
        "item_totalCost": 43.46161788724024,
        "item_profit": 35.578410164614596,
        "item_profitMargin": 0.263161122,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 15,
        "item_supplierId": 8,
        "item_supplier_id": 8,
        "item_supplier_supplierClassId": 6,
        "item_supplier_name": "Casal Santa Maria",
        "item_supplier_class_id": 6,
        "item_supplier_class_name": "Activities",
        "_count_suppliers": 0
    },
    {
        "id": -333333,
        "category": "21 May - Welcome reception at the hotel",
        "background": "#f7f7f7"
    },
    {
        "suppliers": null,
        "id": 30,
        "projectId": 1,
        "name": "Equipment rental including transportation, set up and dismantling ",
        "programName": "21 May - Welcome reception at the hotel",
        "item_id": 42,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-21T14:54:01.000Z",
        "item_ends": "2023-05-19T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 5,
        "item_unit": 1,
        "item_gross": "250",
        "item_total": "1250",
        "item_totalWTaxes": "1250",
        "item_byingCost": 223.8766140382204,
        "item_profitPercent": 0.20747003754877502,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 26.123385961779604,
        "item_totalPayable": 26.123385961779604,
        "item_netBuyingCost": 26.123385961779604,
        "item_totalCost": 26.123385961779604,
        "item_profit": 16.825371921177627,
        "item_profitMargin": 0.20747003754877502,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 30,
        "item_supplierId": 9,
        "item_supplier_id": 9,
        "item_supplier_supplierClassId": 7,
        "item_supplier_name": "Festas & Sonhos",
        "item_supplier_class_id": 7,
        "item_supplier_class_name": "Decor",
        "_count_suppliers": 0
    },
    {
        "suppliers": null,
        "id": 29,
        "projectId": 1,
        "name": "Flower decorations",
        "programName": "21 May - Welcome reception at the hotel",
        "item_id": 41,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-21T14:54:01.000Z",
        "item_ends": "2023-05-19T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 5,
        "item_unit": 1,
        "item_gross": "250",
        "item_total": "1250",
        "item_totalWTaxes": "1250",
        "item_byingCost": 202.71103583865792,
        "item_profitPercent": 0.263553995079,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 47.28896416134208,
        "item_totalPayable": 47.28896416134208,
        "item_netBuyingCost": 47.28896416134208,
        "item_totalCost": 47.28896416134208,
        "item_profit": 39.32925270257579,
        "item_profitMargin": 0.263553995079,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 29,
        "item_supplierId": 9,
        "item_supplier_id": 9,
        "item_supplier_supplierClassId": 7,
        "item_supplier_name": "Festas & Sonhos",
        "item_supplier_class_id": 7,
        "item_supplier_class_name": "Decor",
        "_count_suppliers": 0
    },
    {
        "suppliers": null,
        "id": 28,
        "projectId": 1,
        "name": "Round table centerpieces within the theme and in the same line",
        "programName": "21 May - Welcome reception at the hotel",
        "item_id": 40,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-21T14:54:01.000Z",
        "item_ends": "2023-05-19T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 5,
        "item_unit": 1,
        "item_gross": "250",
        "item_total": "1250",
        "item_totalWTaxes": "1250",
        "item_byingCost": 218.77954062005267,
        "item_profitPercent": 0.16630529591909404,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 31.22045937994733,
        "item_totalPayable": 31.22045937994733,
        "item_netBuyingCost": 31.22045937994733,
        "item_totalCost": 31.22045937994733,
        "item_profit": 30.880136912166105,
        "item_profitMargin": 0.16630529591909404,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 28,
        "item_supplierId": 9,
        "item_supplier_id": 9,
        "item_supplier_supplierClassId": 7,
        "item_supplier_name": "Festas & Sonhos",
        "item_supplier_class_id": 7,
        "item_supplier_class_name": "Decor",
        "_count_suppliers": 0
    },
    {
        "suppliers": null,
        "id": 27,
        "projectId": 1,
        "name": "Rectangular centerpieces within the theme with flowers in shades of blue and white",
        "programName": "21 May - Welcome reception at the hotel",
        "item_id": 39,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-21T14:54:01.000Z",
        "item_ends": "2023-05-19T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 5,
        "item_unit": 1,
        "item_gross": "250",
        "item_total": "1250",
        "item_totalWTaxes": "1250",
        "item_byingCost": 217.446095975636,
        "item_profitPercent": 0.08018729746262321,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 32.55390402436399,
        "item_totalPayable": 32.55390402436399,
        "item_netBuyingCost": 32.55390402436399,
        "item_totalCost": 32.55390402436399,
        "item_profit": 24.65240471008507,
        "item_profitMargin": 0.08018729746262321,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 27,
        "item_supplierId": 9,
        "item_supplier_id": 9,
        "item_supplier_supplierClassId": 7,
        "item_supplier_name": "Festas & Sonhos",
        "item_supplier_class_id": 7,
        "item_supplier_class_name": "Decor",
        "_count_suppliers": 0
    },
    {
        "suppliers": null,
        "id": 26,
        "projectId": 1,
        "name": "Cross back chairs",
        "programName": "21 May - Welcome reception at the hotel",
        "item_id": 38,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-21T14:54:01.000Z",
        "item_ends": "2023-05-19T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 5,
        "item_unit": 1,
        "item_gross": "250",
        "item_total": "1250",
        "item_totalWTaxes": "1250",
        "item_byingCost": 224.44194714342785,
        "item_profitPercent": 0.331713,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 25.558052856572147,
        "item_totalPayable": 25.558052856572147,
        "item_netBuyingCost": 25.558052856572147,
        "item_totalCost": 25.558052856572147,
        "item_profit": 19.7161332654436,
        "item_profitMargin": 0.331713,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 26,
        "item_supplierId": 9,
        "item_supplier_id": 9,
        "item_supplier_supplierClassId": 7,
        "item_supplier_name": "Festas & Sonhos",
        "item_supplier_class_id": 7,
        "item_supplier_class_name": "Decor",
        "_count_suppliers": 0
    },
    {
        "suppliers": null,
        "id": 25,
        "projectId": 1,
        "name": "Large water glasses Marinha Grande",
        "programName": "21 May - Welcome reception at the hotel",
        "item_id": 37,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-21T14:54:01.000Z",
        "item_ends": "2023-05-19T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 5,
        "item_unit": 1,
        "item_gross": "250",
        "item_total": "1250",
        "item_totalWTaxes": "1250",
        "item_byingCost": 245.63831570243389,
        "item_profitPercent": 0.26637789988339033,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 4.361684297566114,
        "item_totalPayable": 4.361684297566114,
        "item_netBuyingCost": 4.361684297566114,
        "item_totalCost": 4.361684297566114,
        "item_profit": 3.3650919254321012,
        "item_profitMargin": 0.26637789988339033,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 25,
        "item_supplierId": 9,
        "item_supplier_id": 9,
        "item_supplier_supplierClassId": 7,
        "item_supplier_name": "Festas & Sonhos",
        "item_supplier_class_id": 7,
        "item_supplier_class_name": "Decor",
        "_count_suppliers": 0
    },
    {
        "suppliers": null,
        "id": 24,
        "projectId": 1,
        "name": "Rattan or rope or porcelain chargers Costa Nova Lisboa",
        "programName": "21 May - Welcome reception at the hotel",
        "item_id": 36,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-21T14:54:01.000Z",
        "item_ends": "2023-05-19T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 5,
        "item_unit": 1,
        "item_gross": "250",
        "item_total": "1250",
        "item_totalWTaxes": "1250",
        "item_byingCost": 216.96206783738563,
        "item_profitPercent": 0.6501979207468649,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 33.03793216261437,
        "item_totalPayable": 33.03793216261437,
        "item_netBuyingCost": 33.03793216261437,
        "item_totalCost": 33.03793216261437,
        "item_profit": 27.274055577776828,
        "item_profitMargin": 0.6501979207468649,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 24,
        "item_supplierId": 9,
        "item_supplier_id": 9,
        "item_supplier_supplierClassId": 7,
        "item_supplier_name": "Festas & Sonhos",
        "item_supplier_class_id": 7,
        "item_supplier_class_name": "Decor",
        "_count_suppliers": 0
    },
    {
        "suppliers": null,
        "id": 23,
        "projectId": 1,
        "name": "Tile napkins in blue and white or Plain white linens",
        "programName": "21 May - Welcome reception at the hotel",
        "item_id": 35,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-21T14:54:01.000Z",
        "item_ends": "2023-05-19T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 5,
        "item_unit": 1,
        "item_gross": "250",
        "item_total": "1250",
        "item_totalWTaxes": "1250",
        "item_byingCost": 221.39934196342074,
        "item_profitPercent": 0.06405793975205776,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 28.60065803657926,
        "item_totalPayable": 28.60065803657926,
        "item_netBuyingCost": 28.60065803657926,
        "item_totalCost": 28.60065803657926,
        "item_profit": 20.65993860498183,
        "item_profitMargin": 0.06405793975205776,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 23,
        "item_supplierId": 9,
        "item_supplier_id": 9,
        "item_supplier_supplierClassId": 7,
        "item_supplier_name": "Festas & Sonhos",
        "item_supplier_class_id": 7,
        "item_supplier_class_name": "Decor",
        "_count_suppliers": 0
    },
    {
        "suppliers": null,
        "id": 22,
        "projectId": 1,
        "name": "Round and rectangular wooden tables with wooden or ironwood legs",
        "programName": "21 May - Welcome reception at the hotel",
        "item_id": 34,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-21T14:54:01.000Z",
        "item_ends": "2023-05-19T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 5,
        "item_unit": 1,
        "item_gross": "250",
        "item_total": "1250",
        "item_totalWTaxes": "1250",
        "item_byingCost": 203.31323248671396,
        "item_profitPercent": 0.22127170054658762,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 46.68676751328604,
        "item_totalPayable": 46.68676751328604,
        "item_netBuyingCost": 46.68676751328604,
        "item_totalCost": 46.68676751328604,
        "item_profit": 37.380487240347726,
        "item_profitMargin": 0.22127170054658762,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 22,
        "item_supplierId": 9,
        "item_supplier_id": 9,
        "item_supplier_supplierClassId": 7,
        "item_supplier_name": "Festas & Sonhos",
        "item_supplier_class_id": 7,
        "item_supplier_class_name": "Decor",
        "_count_suppliers": 0
    },
    {
        "suppliers": null,
        "id": 21,
        "projectId": 1,
        "name": "Bistro tables with wood tops",
        "programName": "21 May - Welcome reception at the hotel",
        "item_id": 33,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-21T14:54:01.000Z",
        "item_ends": "2023-05-19T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 5,
        "item_unit": 1,
        "item_gross": "250",
        "item_total": "1250",
        "item_totalWTaxes": "1250",
        "item_byingCost": 244.76772305388235,
        "item_profitPercent": 0.7619856502027691,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 5.232276946117651,
        "item_totalPayable": 5.232276946117651,
        "item_netBuyingCost": 5.232276946117651,
        "item_totalCost": 5.232276946117651,
        "item_profit": 3.5027909146520337,
        "item_profitMargin": 0.7619856502027691,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 21,
        "item_supplierId": 9,
        "item_supplier_id": 9,
        "item_supplier_supplierClassId": 7,
        "item_supplier_name": "Festas & Sonhos",
        "item_supplier_class_id": 7,
        "item_supplier_class_name": "Decor",
        "_count_suppliers": 0
    },
    {
        "suppliers": null,
        "id": 20,
        "projectId": 1,
        "name": "Wooden bar",
        "programName": "21 May - Welcome reception at the hotel",
        "item_id": 32,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-21T14:54:01.000Z",
        "item_ends": "2023-05-19T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 5,
        "item_unit": 1,
        "item_gross": "250",
        "item_total": "1250",
        "item_totalWTaxes": "1250",
        "item_byingCost": 238.77256631974856,
        "item_profitPercent": 0.575787116225086,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 11.227433680251437,
        "item_totalPayable": 11.227433680251437,
        "item_netBuyingCost": 11.227433680251437,
        "item_totalCost": 11.227433680251437,
        "item_profit": 6.820885301688495,
        "item_profitMargin": 0.575787116225086,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 20,
        "item_supplierId": 9,
        "item_supplier_id": 9,
        "item_supplier_supplierClassId": 7,
        "item_supplier_name": "Festas & Sonhos",
        "item_supplier_class_id": 7,
        "item_supplier_class_name": "Decor",
        "_count_suppliers": 0
    },
    {
        "suppliers": null,
        "id": 19,
        "projectId": 1,
        "name": "Side tables",
        "programName": "21 May - Welcome reception at the hotel",
        "item_id": 31,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-21T14:54:01.000Z",
        "item_ends": "2023-05-19T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 5,
        "item_unit": 1,
        "item_gross": "250",
        "item_total": "1250",
        "item_totalWTaxes": "1250",
        "item_byingCost": 224.33257920521692,
        "item_profitPercent": 0.16962021361479107,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 25.667420794783084,
        "item_totalPayable": 25.667420794783084,
        "item_netBuyingCost": 25.667420794783084,
        "item_totalCost": 25.667420794783084,
        "item_profit": 21.473426509790187,
        "item_profitMargin": 0.16962021361479107,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 19,
        "item_supplierId": 9,
        "item_supplier_id": 9,
        "item_supplier_supplierClassId": 7,
        "item_supplier_name": "Festas & Sonhos",
        "item_supplier_class_id": 7,
        "item_supplier_class_name": "Decor",
        "_count_suppliers": 0
    },
    {
        "suppliers": null,
        "id": 18,
        "projectId": 1,
        "name": "Cushions with tile pattern and plain in axuis and whites",
        "programName": "21 May - Welcome reception at the hotel",
        "item_id": 30,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-21T14:54:01.000Z",
        "item_ends": "2023-05-19T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 5,
        "item_unit": 1,
        "item_gross": "250",
        "item_total": "1250",
        "item_totalWTaxes": "1250",
        "item_byingCost": 201.64072148760357,
        "item_profitPercent": 0.062439884973409576,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 48.35927851239643,
        "item_totalPayable": 48.35927851239643,
        "item_netBuyingCost": 48.35927851239643,
        "item_totalCost": 48.35927851239643,
        "item_profit": 46.76695488275159,
        "item_profitMargin": 0.062439884973409576,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 18,
        "item_supplierId": 9,
        "item_supplier_id": 9,
        "item_supplier_supplierClassId": 7,
        "item_supplier_name": "Festas & Sonhos",
        "item_supplier_class_id": 7,
        "item_supplier_class_name": "Decor",
        "_count_suppliers": 0
    },
    {
        "suppliers": null,
        "id": 17,
        "projectId": 1,
        "name": "Set of somiers",
        "programName": "21 May - Welcome reception at the hotel",
        "item_id": 29,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-21T14:54:01.000Z",
        "item_ends": "2023-05-19T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 5,
        "item_unit": 1,
        "item_gross": "250",
        "item_total": "1250",
        "item_totalWTaxes": "1250",
        "item_byingCost": 205.95949112874007,
        "item_profitPercent": 0.1630909504900997,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 44.04050887125993,
        "item_totalPayable": 44.04050887125993,
        "item_netBuyingCost": 44.04050887125993,
        "item_totalCost": 44.04050887125993,
        "item_profit": 38.7951454297862,
        "item_profitMargin": 0.1630909504900997,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 17,
        "item_supplierId": 9,
        "item_supplier_id": 9,
        "item_supplier_supplierClassId": 7,
        "item_supplier_name": "Festas & Sonhos",
        "item_supplier_class_id": 7,
        "item_supplier_class_name": "Decor",
        "_count_suppliers": 0
    },
    {
        "suppliers": null,
        "id": 16,
        "projectId": 1,
        "name": "Set of rectangular beds",
        "programName": "21 May - Welcome reception at the hotel",
        "item_id": 28,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-21T14:54:01.000Z",
        "item_ends": "2023-05-19T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 5,
        "item_unit": 1,
        "item_gross": "250",
        "item_total": "1250",
        "item_totalWTaxes": "1250",
        "item_byingCost": 249.5469624943799,
        "item_profitPercent": 0.20153857285231624,
        "item_supplierComissions": 0,
        "item_vat": 0.23,
        "item_netPayable": 0.45303750562010237,
        "item_totalPayable": 0.45303750562010237,
        "item_netBuyingCost": 0.45303750562010237,
        "item_totalCost": 0.45303750562010237,
        "item_profit": 0.02178997742301947,
        "item_profitMargin": 0.20153857285231624,
        "item_comissionsDeducted": null,
        "item_comissionsConsidered": null,
        "item_salesOffice": null,
        "item_comissionsClient": null,
        "item_invoiceId": null,
        "item_projectItemId": 16,
        "item_supplierId": 9,
        "item_supplier_id": 9,
        "item_supplier_supplierClassId": 7,
        "item_supplier_name": "Festas & Sonhos",
        "item_supplier_class_id": 7,
        "item_supplier_class_name": "Decor",
        "_count_suppliers": 0
    },
    {
        "id": -444444,
        "category": "22 May - Transportation from Hotel to Airport",
        "background": "#f7f7f7"
    },
    {
        "suppliers": [
            {
                "item_id": 123453,
                "item_name": "",
                "item_status": 400,
                "item_starts": "2023-05-18T14:30:11.000Z",
                "item_ends": "2023-05-18T15:00:00.000Z",
                "item_referenced": false,
                "item_participants": "",
                "item_unit": "",
                "item_gross": "",
                "item_total": "",
                "item_totalWTaxes": "",
                "item_byingCost": "",
                "item_profitPercent": null,
                "item_supplierComissions": null,
                "item_vat": null,
                "item_netPayable": null,
                "item_totalPayable": null,
                "item_netBuyingCost": null,
                "item_totalCost": null,
                "item_profit": null,
                "item_profitMargin": null,
                "item_comissionsDeducted": null,
                "item_comissionsConsidered": null,
                "item_salesOffice": null,
                "item_comissionsClient": null,
                "item_invoiceId": null,
                "item_projectItemId": 1,
                "item_supplierId": 3,
                "item_supplier_id": 3,
                "item_supplier_supplierClassId": 2,
                "item_supplier_name": "Bolt",
                "item_supplier_class_id": 2,
                "item_supplier_class_name": "",
                "id": 1000342,
                "name": ""
            }
        ],
        "id": 11234,
        "projectId": 1566345,
        "name": "Mercedes E-Class with professional driver at disposal",
        "programName": "18 May - Transportation from Airport to Hotel",
        "item_id": 1,
        "item_name": "",
        "item_status": 100,
        "item_starts": "2023-05-18T14:30:01.000Z",
        "item_ends": "2023-05-18T15:00:00.000Z",
        "item_referenced": false,
        "item_participants": 1,
        "item_unit": 1,
        "item_gross": "80",
        "item_total": "80",
        "item_totalWTaxes": "80",
        "item_byingCost": "36",
        "item_profitPercent": "0.40",
        "item_supplierComissions": "0.1",
        "item_vat": 0.23,
        "item_netPayable": "46.74",
        "item_totalPayable": "46.74",
        "item_netBuyingCost": "46.74",
        "item_totalCost": "46.74",
        "item_profit": 44.858251835878754,
        "item_profitMargin": "0.4",
        "item_comissionsDeducted": "17.94",
        "item_comissionsConsidered": "60.06",
        "item_salesOffice": null,
        "item_comissionsClient": "Conference Direct",
        "item_invoiceId": null,
        "item_projectItemId": 1,
        "item_supplierId": 2,
        "item_supplier_id": 2,
        "item_supplier_supplierClassId": 2,
        "item_supplier_name": "Amiroad",
        "item_supplier_class_id": 1,
        "item_supplier_class_name": "Transportation",
        "_count_suppliers": 2
    }
]

export default MockProjectItems