import React, { useState } from 'react'
import TaskAlt from '@mui/icons-material/TaskAlt'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { Spreadsheet } from '@framelink/spreadsheet_ts'
import renderCell from 'components/SpreadsheetCell'
import { Box } from '@mui/material'
import Breadcrumbs from 'components/Breadcrumbs'
import { OpportunitiesData, OpportunitiesFilters, OpportunitiesStatus } from './data'
import OpportunitiesConfig from './config'
import { AutoFixHigh } from '@mui/icons-material'

const icon = {
    fontSize: 18
}

const TOPBAR_HEIGHT = 64
const PANEL_HEIGHT = 78


const Opportunities = () => {
    const [openStatusFilter, setOpenStatusFilter] = useState<HTMLDivElement>()
    const [openTypeFilter, setOpenTypeFilter] = useState<HTMLDivElement>()

    const [checked, setCheck] = useState<number[]>([])

    const handleSelect = (rowIds: number[]) => setCheck(rowIds)

    const handleStatusFilterOpen = (e: React.MouseEvent<HTMLDivElement>) => setOpenStatusFilter(e.currentTarget)
    const handleTypefilterOpen = (e: React.MouseEvent<HTMLDivElement>) => setOpenTypeFilter(e.currentTarget)


    const handleStatusFilterClose = () => setOpenStatusFilter(undefined)
    const handleTypeFilterClose = () => setOpenTypeFilter(undefined)

    const filterStatusItems = (value: string) => handleStatusFilterClose()
    const filterTypeItems = (value: string) => handleTypeFilterClose()

    return (
        <React.Fragment>
            <Box style={{display: 'flex', height: TOPBAR_HEIGHT}}>
                <Breadcrumbs texts={['SolidWorks meeting in Lisbon', 'Opportunities']} />
                <Box style={{margin: '18px', marginLeft: 'auto'}}>
                        <Chip
                            icon={<TaskAlt sx={icon} />}
                            label={'Status'}
                            sx={{
                                cursor: 'pointer',
                                width: '150px'
                            }}
                            onClick={handleStatusFilterOpen}
                        />
                        <Chip 
                            icon={<AutoFixHigh sx={icon} />}
                            label={'Type'}
                            sx={{
                                cursor:'pointer',
                                width:'150px'
                            }}
                            onClick={handleTypefilterOpen}
                        />

                    <Menu
                            anchorEl={openStatusFilter}
                            open={!!openStatusFilter}
                            onClose={handleStatusFilterClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            slotProps={{
                                paper: {
                                    sx: {
                                        marginTop: '10px',
                                        width: '150px'
                                    }
                                }
                            }}
                    >
                        {Object.values(OpportunitiesStatus).map(item => (
                            <MenuItem onClick={() => filterStatusItems(item)}
                                key={item}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Menu>
                    <Menu
                            anchorEl={openTypeFilter}
                            open={!!openTypeFilter}
                            onClose={handleTypeFilterClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            slotProps={{
                                paper: {
                                    sx: {
                                        marginTop: '10px',
                                        width: '150px'
                                    }
                                }
                            }}
                    >
                        {Object.values(OpportunitiesFilters).map(item => (
                            <MenuItem onClick={() => filterTypeItems(item)}
                                key={item}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Menu>
                    
                </Box>
            </Box>
            <Box style={{height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${PANEL_HEIGHT}px)`, display: 'flex'}}>
                <Spreadsheet
                    order={[]}
                    editableHeader={false}
                    fixedCellWidth={100}
                    checkedRows={checked}
                    propId='id'
                    rows={OpportunitiesData}
                    columns={OpportunitiesConfig.columns}
                    heightRow={50}
                    onCheck={handleSelect}
                    renderCell={renderCell}
                />
            </Box>
        </React.Fragment>
    )
}

export default Opportunities