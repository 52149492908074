import React, { useState } from 'react'
import TaskAlt from '@mui/icons-material/TaskAlt'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { Spreadsheet } from '@framelink/spreadsheet_ts'
import renderCell from 'components/SpreadsheetCell'
import { Box } from '@mui/material'
import ProjectsHistoryConfig from './config'
import Breadcrumbs from 'components/Breadcrumbs'
import { ProjectsHistoryData, ProjectsHistoryMembers } from './data'

const icon = {
    fontSize: 18
}

const TOPBAR_HEIGHT = 64
const PANEL_HEIGHT = 78


const ProjectHistory = () => {
    const [openFilter, setOpenFilter] = useState<HTMLDivElement>()

    const [checked, setCheck] = useState<number[]>([])

    const handleSelect = (rowIds: number[]) => setCheck(rowIds)

    const handleTeamMemberOpen = (e: React.MouseEvent<HTMLDivElement>) => setOpenFilter(e.currentTarget)

    const handleFilterClose = () => setOpenFilter(undefined)

    const filterItems = (value: string) => handleFilterClose()

    return (
        <React.Fragment>
            <Box style={{display: 'flex', height: TOPBAR_HEIGHT}}>
                <Breadcrumbs texts={['SolidWorks meeting in Lisbon', 'History']} />
                <Box style={{margin: '18px', marginLeft: 'auto'}}>
                        <Chip
                            icon={<TaskAlt sx={icon} />}
                            label={'Team Member'}
                            sx={{
                                cursor: 'pointer',
                                width: '150px'
                            }}
                            onClick={handleTeamMemberOpen}
                        />
                        <Menu
                            anchorEl={openFilter}
                            open={!!openFilter}
                            onClose={handleFilterClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            slotProps={{
                                paper: {
                                    sx: {
                                        marginTop: '10px',
                                        width: '150px'
                                    }
                                }
                            }}
                    >
                        {Object.values(ProjectsHistoryMembers).map(item => (
                            <MenuItem onClick={() => filterItems(item)}
                                key={item}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Box>
            <Box style={{height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${PANEL_HEIGHT}px)`, display: 'flex'}}>
                <Spreadsheet
                    order={[]}
                    editableHeader={false}
                    fixedCellWidth={100}
                    checkedRows={checked}
                    propId='id'
                    rows={ProjectsHistoryData}
                    columns={ProjectsHistoryConfig.columns}
                    heightRow={50}
                    onCheck={handleSelect}
                    renderCell={renderCell}
                />
            </Box>
        </React.Fragment>
    )
}

export default ProjectHistory