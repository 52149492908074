const ProjectsProgramData = [
    {
        "id": 1,
        "starts": "2023-05-18T00:00:00.000Z",
        "ends": "2023-05-18T00:00:00.000Z",
        "program": "Transportation from Lisbon International Airport to 1 hotel. No drop-offs. Including baggage."
    },
    {
        "id": 2,
        "starts": "2023-05-19T00:00:00.000Z",
        "ends": "2023-05-19T00:00:00.000Z",
        "program": "Transportation from Lisbon International Airport to 1 hotel. No drop-offs. Including baggage."
    },
    {
        "id": 3,
        "starts": "2023-05-19T00:00:00.000Z",
        "ends": "2023-05-19T00:00:00.000Z",
        "program": "Dinner at Rocco"
    },
    {
        "id": 4,
        "starts": "2023-05-20T00:00:00.000Z",
        "ends": "2023-05-20T00:00:00.000Z",
        "program": "Transportation from Lisbon International Airport to 1 hotel. No drop-offs. Including baggage."
    },
    {
        "id": 6,
        "starts": "2023-05-20T00:00:00.000Z",
        "ends": "2023-05-20T00:00:00.000Z",
        "program": "Private Visit to a Winery in Sintra"
    },
    {
        "id": 7,
        "starts": "2023-05-20T00:00:00.000Z",
        "ends": "2023-05-20T00:00:00.000Z",
        "program": "VIP Dinner at 100 Maneiras Bistro"
    },
    {
        "id": 8,
        "starts": "2023-05-21T00:00:00.000Z",
        "ends": "2023-05-21T00:00:00.000Z",
        "program": "Arrival transfer from Lisbon International Airport to Ritz"
    },
    {
        "id": 9,
        "starts": "2023-05-21T00:00:00.000Z",
        "ends": "2023-05-21T00:00:00.000Z",
        "program": "Transportation from Lisbon International Airport to 1 hotel. No drop-offs. Including baggage\t"
    },
    {
        "id": 10,
        "starts": "2023-05-21T00:00:00.000Z",
        "ends": "2023-05-21T00:00:00.000Z",
        "program": "Check in at the hotel "
    },
    {
        "id": 11,
        "starts": "2023-05-21T00:00:00.000Z",
        "ends": "2023-05-21T00:00:00.000Z",
        "program": "Hospitality Desk at the hotel from 8:30 am - 6:00 pm"
    },
    {
        "id": 12,
        "starts": "2023-05-21T00:00:00.000Z",
        "ends": "2023-05-21T00:00:00.000Z",
        "program": "Welcome reception and dinner at the hotel"
    },
    {
        "id": 13,
        "starts": "2023-05-21T00:00:00.000Z",
        "ends": "2023-05-21T00:00:00.000Z",
        "program": "Background Traditional Portuguese Music "
    },
    {
        "id": 14,
        "starts": "2023-05-22T00:00:00.000Z",
        "ends": "2023-05-22T00:00:00.000Z",
        "program": "Transportation from Lisbon International Airport to 1 hotel. No drop-offs. Including baggage\t"
    },
    {
        "id": 15,
        "starts": "2023-05-22T00:00:00.000Z",
        "ends": "2023-05-22T00:00:00.000Z",
        "program": "Tuk-Tuk Treasure Hunt Activity"
    },
    {
        "id": 16,
        "starts": "2023-05-22T00:00:00.000Z",
        "ends": "2023-05-22T00:00:00.000Z",
        "program": "Hospitality Desk at the hotel from 7am – 10:30am and 1:30pm-7pm"
    },
    {
        "id": 17,
        "starts": "2023-05-22T00:00:00.000Z",
        "ends": "2023-05-22T00:00:00.000Z",
        "program": "Dine arounds in Lisbon"
    },
    {
        "id": 18,
        "starts": "2023-05-23T00:00:00.000Z",
        "ends": "2023-05-23T00:00:00.000Z",
        "program": "Breakfast at the Hotel"
    },
    {
        "id": 19,
        "starts": "2023-05-23T00:00:00.000Z",
        "ends": "2023-05-23T00:00:00.000Z",
        "program": "Hospitality Desk at the hotel from 7:00 am - 6:00 pm"
    },
    {
        "id": 20,
        "starts": "2023-05-23T00:00:00.000Z",
        "ends": "2023-05-23T00:00:00.000Z",
        "program": "Half Day Optional activities"
    }
]

export default ProjectsProgramData