import {types} from 'enums/fields'

export const formConfig = [
    {
        title: 'Name',
        type: types.Text,
        name: 'name',
        required: true
    },
    {
        title: 'Start date',
        type: types.Date,
        name: 'starts',
        required: true,
        max: 'ends'
    },
    {
        title: 'End date',
        type: types.Date,
        name: 'ends',
        required: true,
        min: 'starts'
    },
    {
        title: 'Number of attendees',
        type: types.Text,
        name: 'attendees',
        required: false
    },
    {
        title: 'Type',
        type: types.Chip,
        name: 'typeId',
        required: true
    },
    {
        title: 'Status',
        type: types.Chip,
        name: 'statusId',
        required: false
    },
    {
        title: 'Location',
        type: types.Chips,
        name: 'location',
        required: false
    },
    {
        title: 'Team members',
        type: types.Chips,
        name: 'teamMembers',
        required: false
    },
    {
        title: 'Context',
        type: types.Textarea,
        name: 'context',
        required: false
    }
]