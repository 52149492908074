import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import Person from '@mui/icons-material/Person'
import AutoFixHigh from '@mui/icons-material/AutoFixHigh'
import CalendarMonth from '@mui/icons-material/CalendarMonth'
import Mail from '@mui/icons-material/Mail'
import TaskAlt from '@mui/icons-material/TaskAlt'
import Group from '@mui/icons-material/Group'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import {getProjects} from 'slices/project'
import Card from 'components/Card'
import {types} from 'components/Card'
import routes from 'enums/routes'
import projects from 'data/projects'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { Spreadsheet } from '@framelink/spreadsheet_ts'
import ProjectsSpreadsheetConfig from './config'
import renderCell from 'components/SpreadsheetCell'
import { Box } from '@mui/material'
import { randomNumber } from '_protolib/Randomizer'
import MockProjects from 'data/all-projects'

const icon = {
    fontSize: 18
}
const chip = {
    marginLeft: 2,
    cursor: 'pointer',
    width: '150px'
}

const config = {
    id: 'id',
    title: 'name',
    fields: [
        {
            label: 'Client',
            icon: <Person sx={icon} />,
            type: types.Text,
            value: 'client_name'
        },
        {
            label: 'Event Number',
            icon: <ConfirmationNumberIcon sx={icon} />,
            type: types.Text,
            value: 'eventNumber'
        },
        {
            label: 'Start date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'starts'
        },
        {
            label: 'End date',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'ends'
        },
        
        {
            label: 'Status',
            icon: <TaskAlt sx={icon} />,
            type: types.Chip,
            value: 'status_name',
            props: {
                id: 'status_id',
                text: 'status_name',
                background: 'status_color'
            }
        },
    ]
}

const statuses = {
    planning: {value: 'Planning'},
    accepted: {value: 'Accepted'},
    progress: {value: 'In progress'},
    complete: {value: 'Complete'},
    delayed: {value: 'Delayed'},
}

const TOPBAR_HEIGHT = 64
const PANEL_HEIGHT = 78
const SIDEBAR_WIDTH = 0


const ProjectsList = () => {
    const [openFilter, setOpenFilter] = useState<HTMLDivElement>()
    // const projects = useAppSelector(state => state.project.projects)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [checked, setCheck] = useState<number[]>([])

    const handleSelect = (rowIds: number[]) => setCheck(rowIds)
    
    useEffect(() => {
        // dispatch(getProjects())
    }, [dispatch])

    const onRowClick = useCallback((id: number) => {
        const project = MockProjects.find(p => p.id === id)
        const accepted = project && project.statusId === 2
        navigate(`${routes.Project}/${id}/${routes.Profit}?accepted=${accepted}`)
    }, [])


    const handleFilterOpen = (e: React.MouseEvent<HTMLDivElement>) => {
        setOpenFilter(e.currentTarget)
    }

    const handleFilterClose = () => {
        setOpenFilter(undefined)
    }

    const filterItems = (value: string) => {
        handleFilterClose()
    }

    let projects2 = MockProjects

    // if (projects) {
    //     projects2 = projects.map((project: any) => {
    //         const total_to = randomNumber(11000, 100000)
    //         const total_costs = total_to - randomNumber(total_to / 3, total_to / 2)
    //         return Object.assign({}, project, {
    //             total_to,
    //             total_costs,
    //             gross_profit: total_to - total_costs,
    //             conv_speed: randomNumber(15, 40),
    //             client_inter: randomNumber(3, 11)
    //         })
    //     })
    // }

    return (
        <React.Fragment>
            <Box style={{margin: '20px', display: 'flex'}}>
                    <Chip
                        icon={<TaskAlt sx={icon} />}
                        label={'Status'}
                        sx={{
                            marginLeft: 'auto',
                            cursor: 'pointer',
                            width: '150px'
                        }}
                        onClick={handleFilterOpen}
                    />
                    <Chip icon={<AutoFixHigh sx={icon} />} label="Type" sx={chip} />
                    <Menu
                        anchorEl={openFilter}
                        open={!!openFilter}
                        onClose={handleFilterClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        slotProps={{
                            paper: {
                                sx: {
                                    marginTop: '10px',
                                    width: '150px'
                                }
                            }
                        }}
                >
                    {Object.values(statuses).map(item => (
                        <MenuItem onClick={() => filterItems(item.value)}
                            key={item.value}
                            value={item.value}
                        >
                            {item.value}
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
            <Box style={{height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${PANEL_HEIGHT}px)`, display: 'flex'}}>
                <Spreadsheet
                    order={[]}
                    editableHeader={false}
                    fixedCellWidth={100}
                    checkedRows={checked}
                    propId='id'
                    rows={projects2}
                    columns={ProjectsSpreadsheetConfig.columns}
                    heightRow={50}
                    onCheck={handleSelect}
                    renderCell={renderCell}
                    onRowClick={onRowClick}
                    // totals={totals}
                />
            </Box>
        </React.Fragment>
    )
}

export default ProjectsList