const MockProjects = [
    {
      "id": 1,
      "name": "SolidWorks meeting in Lisbon",
      "eventNumber": "PT.VOA.22.0093",
      "starts": "2023-05-22T00:00:00.000Z",
      "ends": "2023-05-25T00:00:00.000Z",
      "participants": 19,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 1,
      "statusId": 1,
      "client_id": 1,
      "client_name": "Switch Event Marketing",
      "client_locationId": 1,
      "client_location_id": 1,
      "client_location_name": "Portugal",
      "status_id": 1,
      "status_name": "Planning",
      "status_color": "#f48fb1",
      "status_protected": true
    },
    {
      "id": 2,
      "name": "URNS_3_2023",
      "eventNumber": "US.URNS.23.5189",
      "starts": "2023-08-04T00:00:00.999Z",
      "ends": "2023-08-04T00:00:00.999Z",
      "participants": 28,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 2,
      "statusId": 2,
      "client_id": 2,
      "client_name": "URNS",
      "client_locationId": 2,
      "client_location_id": 2,
      "client_location_name": "US",
      "status_id": 2,
      "status_name": "Accepted",
      "status_color": "#81d4fa",
      "status_protected": true
    },
    {
      "id": 3,
      "name": "CHAW_5_2023",
      "eventNumber": "US.CHAW.23.9905",
      "starts": "2023-07-10T00:00:00.999Z",
      "ends": "2023-07-10T00:00:00.999Z",
      "participants": 29,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 3,
      "statusId": 3,
      "client_id": 3,
      "client_name": "CHAW",
      "client_locationId": 2,
      "client_location_id": 2,
      "client_location_name": "US",
      "status_id": 3,
      "status_name": "In progress",
      "status_color": "#ffe082",
      "status_protected": true
    },
    {
      "id": 4,
      "name": "XLLA_1_2023",
      "eventNumber": "SP.XLLA.23.7407",
      "starts": "2023-09-13T00:00:00.999Z",
      "ends": "2023-09-13T00:00:00.999Z",
      "participants": 42,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 4,
      "statusId": 4,
      "client_id": 4,
      "client_name": "XLLA",
      "client_locationId": 3,
      "client_location_id": 3,
      "client_location_name": "Spain",
      "status_id": 4,
      "status_name": "Complete",
      "status_color": "#a5d6a7",
      "status_protected": true
    },
    {
      "id": 5,
      "name": "FTTR_6_2023",
      "eventNumber": "US.FTTR.23.9346",
      "starts": "2023-07-21T00:00:00.999Z",
      "ends": "2023-07-21T00:00:00.999Z",
      "participants": 14,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 5,
      "statusId": 1,
      "client_id": 5,
      "client_name": "FTTR",
      "client_locationId": 2,
      "client_location_id": 2,
      "client_location_name": "US",
      "status_id": 1,
      "status_name": "Planning",
      "status_color": "#f48fb1",
      "status_protected": true
    },
    {
      "id": 6,
      "name": "QJXD_1_2023",
      "eventNumber": "PO.QJXD.23.3977",
      "starts": "2023-09-16T00:00:00.999Z",
      "ends": "2023-09-16T00:00:00.999Z",
      "participants": 50,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 6,
      "statusId": 1,
      "client_id": 6,
      "client_name": "QJXD",
      "client_locationId": 1,
      "client_location_id": 1,
      "client_location_name": "Portugal",
      "status_id": 1,
      "status_name": "Planning",
      "status_color": "#f48fb1",
      "status_protected": true
    },
    {
      "id": 7,
      "name": "TCBK_7_2023",
      "eventNumber": "BR.TCBK.23.9128",
      "starts": "2023-09-17T00:00:00.999Z",
      "ends": "2023-09-17T00:00:00.999Z",
      "participants": 37,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 7,
      "statusId": 4,
      "client_id": 7,
      "client_name": "TCBK",
      "client_locationId": 4,
      "client_location_id": 4,
      "client_location_name": "Brasil",
      "status_id": 4,
      "status_name": "Complete",
      "status_color": "#a5d6a7",
      "status_protected": true
    },
    {
      "id": 8,
      "name": "FQJQ_6_2023",
      "eventNumber": "SP.FQJQ.23.1212",
      "starts": "2023-07-18T00:00:00.999Z",
      "ends": "2023-07-18T00:00:00.999Z",
      "participants": 38,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 8,
      "statusId": 4,
      "client_id": 8,
      "client_name": "FQJQ",
      "client_locationId": 3,
      "client_location_id": 3,
      "client_location_name": "Spain",
      "status_id": 4,
      "status_name": "Complete",
      "status_color": "#a5d6a7",
      "status_protected": true
    },
    {
      "id": 9,
      "name": "FEAL_9_2023",
      "eventNumber": "US.FEAL.23.1743",
      "starts": "2023-08-09T00:00:00.999Z",
      "ends": "2023-08-09T00:00:00.999Z",
      "participants": 46,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 9,
      "statusId": 5,
      "client_id": 9,
      "client_name": "FEAL",
      "client_locationId": 2,
      "client_location_id": 2,
      "client_location_name": "US",
      "status_id": 5,
      "status_name": "Delayed",
      "status_color": "#9fa8da",
      "status_protected": true
    },
    {
      "id": 10,
      "name": "UMFW_8_2023",
      "eventNumber": "BR.UMFW.23.4943",
      "starts": "2023-07-26T00:00:00.999Z",
      "ends": "2023-07-26T00:00:00.999Z",
      "participants": 17,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 10,
      "statusId": 5,
      "client_id": 10,
      "client_name": "UMFW",
      "client_locationId": 4,
      "client_location_id": 4,
      "client_location_name": "Brasil",
      "status_id": 5,
      "status_name": "Delayed",
      "status_color": "#9fa8da",
      "status_protected": true
    },
    {
      "id": 11,
      "name": "YXQS_1_2023",
      "eventNumber": "SP.YXQS.23.4032",
      "starts": "2023-07-29T00:00:00.999Z",
      "ends": "2023-07-29T00:00:00.999Z",
      "participants": 20,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 11,
      "statusId": 2,
      "client_id": 11,
      "client_name": "YXQS",
      "client_locationId": 3,
      "client_location_id": 3,
      "client_location_name": "Spain",
      "status_id": 2,
      "status_name": "Accepted",
      "status_color": "#81d4fa",
      "status_protected": true
    },
    {
      "id": 12,
      "name": "NRXD_6_2023",
      "eventNumber": "BR.NRXD.23.6906",
      "starts": "2023-08-19T00:00:00.999Z",
      "ends": "2023-08-19T00:00:00.999Z",
      "participants": 47,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 12,
      "statusId": 1,
      "client_id": 12,
      "client_name": "NRXD",
      "client_locationId": 4,
      "client_location_id": 4,
      "client_location_name": "Brasil",
      "status_id": 1,
      "status_name": "Planning",
      "status_color": "#f48fb1",
      "status_protected": true
    },
    {
      "id": 13,
      "name": "IMQP_6_2023",
      "eventNumber": "PO.IMQP.23.9711",
      "starts": "2023-07-24T00:00:00.999Z",
      "ends": "2023-07-24T00:00:00.999Z",
      "participants": 37,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 13,
      "statusId": 2,
      "client_id": 13,
      "client_name": "IMQP",
      "client_locationId": 1,
      "client_location_id": 1,
      "client_location_name": "Portugal",
      "status_id": 2,
      "status_name": "Accepted",
      "status_color": "#81d4fa",
      "status_protected": true
    },
    {
      "id": 14,
      "name": "VQXA_1_2023",
      "eventNumber": "PO.VQXA.23.7887",
      "starts": "2023-08-19T00:00:00.999Z",
      "ends": "2023-08-19T00:00:00.999Z",
      "participants": 34,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 14,
      "statusId": 3,
      "client_id": 14,
      "client_name": "VQXA",
      "client_locationId": 1,
      "client_location_id": 1,
      "client_location_name": "Portugal",
      "status_id": 3,
      "status_name": "In progress",
      "status_color": "#ffe082",
      "status_protected": true
    },
    {
      "id": 15,
      "name": "SKQX_5_2023",
      "eventNumber": "US.SKQX.23.8463",
      "starts": "2023-07-13T00:00:00.999Z",
      "ends": "2023-07-13T00:00:00.999Z",
      "participants": 25,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 15,
      "statusId": 2,
      "client_id": 15,
      "client_name": "SKQX",
      "client_locationId": 2,
      "client_location_id": 2,
      "client_location_name": "US",
      "status_id": 2,
      "status_name": "Accepted",
      "status_color": "#81d4fa",
      "status_protected": true
    },
    {
      "id": 16,
      "name": "OJUK_5_2023",
      "eventNumber": "SP.OJUK.23.8689",
      "starts": "2023-08-10T00:00:00.999Z",
      "ends": "2023-08-10T00:00:00.999Z",
      "participants": 20,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 16,
      "statusId": 2,
      "client_id": 16,
      "client_name": "OJUK",
      "client_locationId": 3,
      "client_location_id": 3,
      "client_location_name": "Spain",
      "status_id": 2,
      "status_name": "Accepted",
      "status_color": "#81d4fa",
      "status_protected": true
    },
    {
      "id": 17,
      "name": "XRIW_5_2023",
      "eventNumber": "SP.XRIW.23.2616",
      "starts": "2023-08-21T00:00:00.999Z",
      "ends": "2023-08-21T00:00:00.999Z",
      "participants": 23,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 17,
      "statusId": 2,
      "client_id": 17,
      "client_name": "XRIW",
      "client_locationId": 3,
      "client_location_id": 3,
      "client_location_name": "Spain",
      "status_id": 2,
      "status_name": "Accepted",
      "status_color": "#81d4fa",
      "status_protected": true
    },
    {
      "id": 18,
      "name": "SAKE_3_2023",
      "eventNumber": "PO.SAKE.23.4871",
      "starts": "2023-07-01T00:00:00.999Z",
      "ends": "2023-07-01T00:00:00.999Z",
      "participants": 14,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 18,
      "statusId": 1,
      "client_id": 18,
      "client_name": "SAKE",
      "client_locationId": 1,
      "client_location_id": 1,
      "client_location_name": "Portugal",
      "status_id": 1,
      "status_name": "Planning",
      "status_color": "#f48fb1",
      "status_protected": true
    },
    {
      "id": 19,
      "name": "DGJV_4_2023",
      "eventNumber": "SP.DGJV.23.8909",
      "starts": "2023-09-20T00:00:00.999Z",
      "ends": "2023-09-20T00:00:00.999Z",
      "participants": 35,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 19,
      "statusId": 4,
      "client_id": 19,
      "client_name": "DGJV",
      "client_locationId": 3,
      "client_location_id": 3,
      "client_location_name": "Spain",
      "status_id": 4,
      "status_name": "Complete",
      "status_color": "#a5d6a7",
      "status_protected": true
    },
    {
      "id": 20,
      "name": "XPSJ_4_2023",
      "eventNumber": "SP.XPSJ.23.1125",
      "starts": "2023-09-24T00:00:00.999Z",
      "ends": "2023-09-24T00:00:00.999Z",
      "participants": 13,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 20,
      "statusId": 5,
      "client_id": 20,
      "client_name": "XPSJ",
      "client_locationId": 3,
      "client_location_id": 3,
      "client_location_name": "Spain",
      "status_id": 5,
      "status_name": "Delayed",
      "status_color": "#9fa8da",
      "status_protected": true
    },
    {
      "id": 21,
      "name": "ANJX_9_2023",
      "eventNumber": "BR.ANJX.23.4254",
      "starts": "2023-09-02T00:00:00.999Z",
      "ends": "2023-09-02T00:00:00.999Z",
      "participants": 32,
      "taxClient": 1,
      "taxPayable": 0,
      "taxComission": 23,
      "clientId": 21,
      "statusId": 5,
      "client_id": 21,
      "client_name": "ANJX",
      "client_locationId": 4,
      "client_location_id": 4,
      "client_location_name": "Brasil",
      "status_id": 5,
      "status_name": "Delayed",
      "status_color": "#9fa8da",
      "status_protected": true
    }
]

export default MockProjects