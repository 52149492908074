import {AutoFixHigh } from "@mui/icons-material";
import ScoreIcon from '@mui/icons-material/Score';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import Card, { types } from 'components/Card';
import React, { useMemo, useState } from "react";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import Tooltip from '@mui/material/Tooltip'
import Fab from '@mui/material/Fab'
import Add from '@mui/icons-material/Add'
import Breadcrumbs from "components/Breadcrumbs";
import TaskAlt from "@mui/icons-material/TaskAlt";
import EditForm from "components/EditForm";
import { formConfig, formConfigEvaluation} from "enums/evaluationPerfomance";
import { EvaluationData } from "./evaluationData";
import styled from "@emotion/styled";
import { VoquinWayData } from "./voquin_wayData";
import Person from "@mui/icons-material/Person";
import BusinessIcon from '@mui/icons-material/Business';

const icon = {
  fontSize: 18
}

const TOPBAR_HEIGHT = 64

const Bottom = styled.div`
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    justify-content: flex-end;
`

const config ={
  id: 'id',
  title: 'name',
  fields: [
    {
      label: 'Team Member',
      icon: <Person sx={icon}/>,
      type: types.Chip,
      value: 'teamMember'
    },
    {
      label: 'Manager',
      icon: <Person sx={icon}/>,
      type: types.Chip,
      value: 'manager'
    },
    {
      label: 'Role',
      icon: <AutoFixHigh sx={icon}/>,
      type: types.Text,
      value: 'role'
    },
    {
      label: 'Department',
      icon: <BusinessIcon sx={icon}/>,
      type: types.Text,
      value: 'department'
    },
    {
      label: 'Review Score',
      icon: <ScoreIcon sx={icon}/>,
      type: types.Text,
      value: 'review_score'
    },
    {
      label: 'Prior Review',
      icon: <ScoreIcon sx={icon}/>,
      type: types.Text,
      value: 'prior_score'
    },
    {
        label: 'Change',
        icon: <ChangeCircleIcon sx={icon}/>,
        type: types.Text,
        value: 'change'
      },
  ]
}

const configEvaluation = {
  id: 'id',
  title: 'name',
  fields: [
    {
      label: 'Culture',
      icon: <ScoreIcon sx={icon}/>,
      type: types.Text,
      value: 'culture'
    },
    {
      label: 'Adaptability',
      icon: <ScoreIcon sx={icon}/>,
      type: types.Text,
      value: 'adaptability'
    },
    {
      label: 'Resilience',
      icon: <ScoreIcon sx={icon}/>,
      type: types.Text,
      value: 'resilience'
    },
    {
      label: 'Empathy',
      icon: <ScoreIcon sx={icon}/>,
      type: types.Text,
      value: 'empathy'
    },
    {
      label: 'Ownership',
      icon: <ScoreIcon sx={icon}/>,
      type: types.Text,
      value: 'ownership'
    },
    {
      label: 'Collaborative',
      icon: <ScoreIcon sx={icon}/>,
      type: types.Text,
      value: 'collaborative'
    },
    {
      label: 'Team Management',
      icon: <ScoreIcon sx={icon}/>,
      type: types.Text,
      value: 'team_management'
    },
    {
      label: 'Leadership',
      icon: <ScoreIcon sx={icon}/>,
      type: types.Text,
      value: 'leadership'
    },
    {
      label: 'Global Vision Understanding',
      icon: <ScoreIcon sx={icon}/>,
      type: types.Text,
      value: 'gvu'
    },
  ]
}

const EvaluationPerformance = () => {
  const project = EvaluationData[0]
  const voquin_way = VoquinWayData[0]

  const [openForm, setOpenForm] = useState<boolean>(false)
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const [openCreateEvaluation, setOpenCreateEvaluation] = useState<boolean>(false)

  const handleCreateEvaluationOpen = () => {
    setOpenCreateEvaluation(true)
  }

  const handleCreateEvaluationClose = () => {
    setOpenCreateEvaluation(false)
  }

  const handleCreateEvaluationConfirm = () => {
    handleCreateEvaluationClose()
  }

  const handleFormOpen = () => {
      setOpenForm(true)
  }

  const handleFormClose = () => {
    setOpenForm(false)
}

const handleConfirm = () => {
    handleFormClose()
}

const handlePopupOpen = () => {
    setOpenPopup(true)
}

const handlePopupClose = () => {
    setOpenPopup(false)
}

const handleDelete = () => {
    handlePopupClose()
}

const initialValues = useMemo(() => {
  return {
      teamMember: project ? project.teamMember : '',
      manager: project ? project.manager : null,
      role: project ? project.role : '',
      department: project ? project.department : null,
      review_score: project ? project.review_score : '',
      prior_score: project ? project.prior_score : '',
      change: project ? project.change : ''
  }
}, [project])

/*const initialValuesEvaluation = useMemo(() => {
  return {
      culture: voquin_way ? voquin_way.culture : '',
      adaptability : voquin_way ? voquin_way.adaptability : '',
      resilience : voquin_way ? voquin_way.resilience : '',
      empathy: voquin_way ? voquin_way.empathy : '',
      ownership: voquin_way ? voquin_way.ownership : '',
      team_management: voquin_way ? voquin_way.team_management : '',
      leadership: voquin_way ? voquin_way.leadership: '',
      gvu: voquin_way ? voquin_way.gvu : ''
  }
}, [project])*/


  return (
    <React.Fragment>
        <Box style ={{display: 'flex', height: TOPBAR_HEIGHT}}>
          <Breadcrumbs texts={['Perfomance Evaluation', '2022', 'Projects Department']}/>
            <Box style={{margin:'18px', marginLeft: 'auto'}}>
            <Chip
                            icon={<TaskAlt sx={icon} />}
                            label={'Change Team Member'}
                            sx={{
                                cursor: 'pointer',
                                width: '150px'
                            }}
                            onClick={handleFormOpen}
                        />
            <Chip 
                            icon={<AutoFixHigh sx={icon} />}
                            label={'Delete Team Member'}
                            sx={{
                                cursor:'pointer',
                                width:'150px'
                            }}
                            onClick={handlePopupOpen}
                        />

                </Box>
            </Box>
                         {project ? (
       <div> 
        <Card config={config} data={project} presentation />
        </div>
      ) : (
        <div>No project data available.</div>
      )}
                               {voquin_way ? (
       <div> 
        <Card config={configEvaluation} data={voquin_way} presentation />
        </div>
      ) : (
        <div>No evaluation data available.</div>
      )}

 <EditForm
              open={openForm}
              handleClose={handleFormClose}
              handleConfirm={handleConfirm}
              title={'Change Team Member'}
              config={formConfig}
              initialValues={initialValues}         
              />

<Dialog
                open={openPopup}
                onClose={handlePopupClose}
            >
                <DialogTitle>
                    Confirm your action
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete {project && project.teamMember} review score?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePopupClose}>Cancel</Button>
                    <Button onClick={handleDelete} variant='contained'>Confirm</Button>
                </DialogActions>
            </Dialog>
            
            <Bottom>
            <Tooltip title="Create Evaluation" placement="top">
                    <Fab onClick={() => handleCreateEvaluationOpen()} color="secondary" size='small' sx={{marginLeft: '20px'}}>
                        <Add />
                    </Fab>
                </Tooltip>
            </Bottom>
            <EditForm
        open={openCreateEvaluation}
        handleClose={handleCreateEvaluationClose}
        handleConfirm={handleCreateEvaluationConfirm}
        title={'Create Evaluation'}
        config={formConfigEvaluation} initialValues={{}}                     
              />
       
    </React.Fragment>
  );
};

export default EvaluationPerformance;