import {createTheme} from '@mui/material/styles'

const theme = createTheme({
    typography: {
        fontSize: 14,
    },
    spacing: 10,
    palette: {
        primary: {
            main: '#f9af10'
        },
        secondary: {
            main: '#e69238'
        },
        info: {
            main: '#ffffff'
        },
        success: {
            main: '#34a853'
        },
        error: {
            main: '#ea4435'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    textTransform: 'none',
                    letterSpacing: 0.5,
                    padding: '10px 15px'
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                }
            }
        }
      },
})

export default theme