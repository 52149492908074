const MockSpreadsheetPnl = {
    "columns": [
      {
        "id": 1,
        "name": "name",
        "title": "P&L item",
        "width": 550,
        "type": "nameorcategory",
        "tableId": 1
      },
      {
        "id": 3,
        "name": "item_supplier_class_name",
        "title": "Category",
        "width": 150,
        "type": "chip",
        "tableId": 1
      },
      {
        "id": 4,
        "name": "item_status",
        "title": "",
        "width": 90,
        "type": "itemStatus",
        "tableId": 1
      },
      {
        "id": 5,
        "name": "item_supplier_name",
        "title": "Supplier",
        "width": 220,
        "type": "supplier",
        "tableId": 1
      },
      {
        "id": 6,
        "name": "item_unit",
        "title": "Unit",
        "width": 80,
        "type": "unit",
        "tableId": 1
      },
      {
        "id": 7,
        "name": "item_participants",
        "title": "Participants",
        "width": 120,
        "type": "number",
        "tableId": 1
      },
      {
        "id": 8,
        "name": "item_gross",
        "title": "Gross unit cost",
        "width": 200,
        "type": "money",
        "tableId": 1
      },
      {
        "id": 9,
        "name": "item_total",
        "title": "Total",
        "width": 150,
        "type": "money",
        "tableId": 1
      },
      {
        "id": 10,
        "name": "item_totalWTaxes",
        "title": "Total wORTaxes",
        "width": 200,
        "type": "money",
        "tableId": 1
      },
      {
        "id": 11,
        "name": "item_byingCost",
        "title": "Bying Cost",
        "width": 200,
        "type": "money",
        "tableId": 1
      },
      {
        "id": 12,
        "name": "item_profitPercent",
        "title": "% Profit",
        "width": 200,
        "type": "percent",
        "tableId": 1
      },
      {
        "id": 13,
        "name": "item_supplierComissions",
        "title": "Comissions from supplier",
        "width": 200,
        "type": "percent",
        "tableId": 1
      },
      {
        "id": 14,
        "name": "item_vat",
        "title": "VAT",
        "width": 200,
        "type": "percent",
        "tableId": 1
      },
      {
        "id": 15,
        "name": "item_netPayable",
        "title": "Net Payable",
        "width": 200,
        "type": "money",
        "tableId": 1
      },
      {
        "id": 16,
        "name": "item_totalPayable",
        "title": "Total Payable",
        "width": 200,
        "type": "money",
        "tableId": 1
      },
      {
        "id": 17,
        "name": "item_netBuyingCost",
        "title": "NET Bying Cost",
        "width": 200,
        "type": "money",
        "tableId": 1
      },
      {
        "id": 18,
        "name": "item_totalCost",
        "title": "Total Cost",
        "width": 200,
        "type": "money",
        "tableId": 1
      },
      {
        "id": 19,
        "name": "item_profit",
        "title": "Profit",
        "width": 200,
        "type": "money",
        "tableId": 1
      },
      {
        "id": 21,
        "name": "item_comissionsDeducted",
        "title": "Comissions Deducted",
        "width": 200,
        "type": "money",
        "tableId": 1
      },
      {
        "id": 22,
        "name": "item_comissionsConsidered",
        "title": "Comissions Considered",
        "width": 200,
        "type": "money",
        "tableId": 1
      },
      {
        "id": 23,
        "name": "item_salesOffice",
        "title": "Sales Office",
        "width": 200,
        "type": "text",
        "tableId": 1
      },
      {
        "id": 24,
        "name": "item_comissionsClient",
        "title": "Client Agency",
        "width": 200,
        "type": "text",
        "tableId": 1
      }
    ],
    "id": 1,
    "name": "project_items",
    "propId": "item_id",
    "propCollapseCount": "_count_suppliers",
    "_count_columns": 24
}

export default MockSpreadsheetPnl