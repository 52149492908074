const ProjectsSpreadsheetConfig = {
    columns: [
        { name: "name", title: "Project", width: 280, type: "text" },
        { name: "status_name", title: "Status", width: 150, type: "status" },
        { name: "client_name", title: "Client", width: 250, type: "client" },
        { name: "client_location_name", title: "Location", width: 120, type: "client" },
        { name: "eventNumber", title: "Event number", width: 170, type: "text" },
        { name: "starts", title: "Starts", width: 150, type: "dateonly" },
        { name: "ends", title: "Ends", width: 150, type: "dateonly" },
        { name: "total_to", title: "Total T/O", width: 150, type: "money" },
        { name: "total_costs", title: "Total costs", width: 150, type: "money" },
        { name: "gross_profit", title: "Gross Profit", width: 150, type: "money" },
        { name: "conv_speed", title: "Speed of conversion", width: 150, type: "convSpeed" },
        { name: "client_inter", title: "Client interactions", width: 150, type: "clientInter" }
    ]
}

export default ProjectsSpreadsheetConfig