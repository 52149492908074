import { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import { Tooltip, useTheme } from "@mui/material";
// import ModalProjectDetails from "./ModalProjectDetails";
import { renderMoney } from "oldcomponents/utils";
import ModalProjectDetails from "oldcomponents/ModalProjectDetails";

interface IPropsProjectStat {
    name: string
    value: any
    color?: any
    tooltip?: any
    onClick?: any
    render?: Function
}

function ProjectStat(props: IPropsProjectStat) {
    const theme: any = useTheme() // todo - replace any
    const info = '#708085' || theme?.palette?.info?.dark || ''
    const value: any = props.render ? props.render(props.value) : props.value;

    if (props.tooltip) {
        return (
            <Tooltip title={props.tooltip}>
                <Box style={{ width: 150, display: 'inline-block', cursor: 'pointer' }} onClick={props.onClick}>
            <div style={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif', textAlign: 'center', marginBottom: 5, fontSize: '14px', color: info }}>{props.name}</div>
            <h3 style={{ textAlign: 'center', margin: 0, color: props.color }}>{value}</h3>
                </Box>
            </Tooltip>
        );
    }
    return (
        <Box style={{ width: 150, display: 'inline-block', cursor: 'pointer' }} onClick={props.onClick}>
            <div style={{ fontFamily: '"Roboto","Helvetica","Arial",sans-serif', textAlign: 'center', marginBottom: 5, fontSize: '14px', color: info }}>{props.name}</div>
            <h3 style={{ textAlign: 'center', margin: 0, color: props.color }}>{value}</h3>
        </Box>
    );
}

interface IPropsPanelProjectDetails {
    info: any
    stats: any
    kpi: any
    panel: string
}

export default function PanelProjectDetails(props: IPropsPanelProjectDetails) {
    const theme: any = useTheme() // todo - replace any

    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [modalTab, setModalTab] = useState<number>(0)

    const openModal = (tab: number): void => {
        setModalTab(tab)
        setModalOpen(true)
    }
    const onInfoClick = () => openModal(0)
    const onSummaryClick = () => openModal(1)
    const onKpiClick = () => openModal(2)

    const success = theme?.palette?.success?.main || ''
    const error = theme?.palette?.error?.main || ''
    const info = theme?.palette?.info?.dark || ''

    return (
        <Fragment>
            <ModalProjectDetails
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                info={props.info}
                summary={props.stats}
                kpi={props.kpi}
                tab={modalTab}
                />
            <Box style={{
                // height: 100,
                padding: '20px 0',
                marginLeft: 'auto',
                display: 'flex',
                // alignItems: 'center'
                }}>
                <Box style={{ marginLeft: 'auto', whiteSpace: 'nowrap' }}>
                    <div>
                        <ProjectStat name="Total T/O" value={renderMoney(props.stats.totalTO)} onClick={onSummaryClick} />
                        <ProjectStat name="Total costs" value={renderMoney(props.stats.totalCosts)} onClick={onSummaryClick} />
                        <ProjectStat name="Gross Profit" value={renderMoney(props.stats.gp)} onClick={onSummaryClick} />
                        <ProjectStat tooltip="Below the average speed of conversion" name="Speed of conversion" value={props.kpi.convSpeed} color={success} onClick={onKpiClick} />
                        <ProjectStat tooltip="Above the average number of client interactions" name="Client interactions" value={props.kpi.clientInteractions} color={error} onClick={onKpiClick} />
                    </div>
                </Box>
            </Box>
        </Fragment>
    )
}