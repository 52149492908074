import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip, useTheme } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { renderMoney, renderPercent } from "oldcomponents/utils";
import { Spreadsheet } from "@framelink/spreadsheet_ts";
import FolderList from "./SuppliersList";
import SuppliersList from "./SuppliersList";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface IPropsModalSelectSupplier {
    open: boolean
    onClose: Function
    // info: any,
    // summary: any,
    // kpi: any,
    // tab: number
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            { value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            ) }
        </div>
    )
}

const InfoItem = (props: { name: string, value: any, color?: any }) => {
    return (
        <Box>
            <h5 style={{ marginBottom: 5, color: props.color }}>{props.name}</h5>
            {props.value}
        </Box>
    )
}

const KpiItem = (props: { name: string, value: any, color?: any, tooltip?: string }) => {
    return (
        <Box>
            <h5 style={{ marginBottom: 5 }}>{props.name}</h5>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ display: 'inline-block', width: 110 }}>{props.value}</span>
                {props.tooltip && (<Tooltip title={props.tooltip}>
                    <InfoIcon color="primary" style={{ marginLeft: 10, color: props.color }} />
                </Tooltip>)}
            </div>
        </Box>
    )
}

const SummaryItem = (props: { name: string, value: any }) => {
    return (
        <Box style={{ display: 'flex', alignItems: 'center', padding: '15px 0' }}>
            <h5 style={{ margin: 0, marginBottom: 5 }}>{props.name}</h5>
            <h3 style={{ margin: 0, marginLeft: 'auto'}}>{props.value}</h3>
        </Box>
    )
}

const SummarySubItem = (props: { name: string, value: any }) => {
    return (
        <Box style={{ display: 'flex', alignItems: 'center', padding: '15px 0' }}>
            <div style={{ paddingLeft: 20, fontSize: '0.83em', margin: 0, marginBottom: 5 }}>{props.name}</div>
            <h5 style={{ margin: 0, marginLeft: 'auto'}}>{props.value}</h5>
        </Box>
    )
}

export default function ModalSelectSupplier(props: IPropsModalSelectSupplier) {
    const theme: any = useTheme() // todo - replace any

    const [open, setOpen] = useState<boolean>(props.open)

    const [checked, setCheck] = useState<number[]>([])

    const VoqinColorSuccess = theme?.palette?.success?.main || ''
    const VoqinColorError = theme?.palette?.error?.main || ''

    useEffect(() => setOpen(props.open), [props.open])

    const onClose = (): void => props.onClose()

    const getTabProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    const marginBottom = 1.8

    const [filters, setFilters] = useState({
        zone: 'lisboa',
        capacity: '25'
    })

    const onClearFilters = () => {
        console.log('sd')
        setFilters({
            zone: '',
            capacity: ''
        })
    }

    return (
        <React.Fragment>
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle sx={{ padding: 4 }}>
                    Add Accomodation Suppliers
                </DialogTitle>
                <DialogContent sx={{ padding: 4 }}>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ padding: 1, width: '300px' }}>
                            <TextField label="Zone" sx={{ width: '100%', marginBottom }} value={filters.zone} />
                            <TextField label="Capacity" sx={{ width: '100%', marginBottom }} value={filters.capacity} />
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Stars</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    label="Stars" sx={{ width: '100%', marginBottom }}
                                >
                                    <MenuItem value={5}>5 stars</MenuItem>
                                    <MenuItem value={4}>4 stars</MenuItem>
                                    <MenuItem value={3}>3 stars</MenuItem>
                                </Select>
                            </FormControl>
                            <Button onClick={onClearFilters}>Clear filters</Button>
                        </Box>
                        <Box style={{ height: '370px', overflow: 'auto' }}>
                            <SuppliersList/>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ padding: 4 }}>
                    <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onClose} variant='contained'>Request Quotation</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}