import React, {useEffect, useState} from 'react'
import {matchPath, useLocation} from 'react-router-dom'
import Link from '@mui/material/Link'
import BreadcrumbsComponent from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const links = {
    padding: '20px 15px',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14px'
}

const typography = {
    letterSpacing: '0.5px',
    color: '#708085',
    fontSize: '14px'
}

interface IComponent {
    texts: string[]
}

const Breadcrumbs = (props: IComponent) => {
    const { pathname } = useLocation()

    return (
        <BreadcrumbsComponent separator={<NavigateNextIcon fontSize="small" />} sx={links}>
            <Typography key="2" color="text.primary" sx={typography}>
                {props.texts[0] || ''}
            </Typography>
            <Link underline='none' key="1" color="inherit" href="/projects">
                {props.texts[1] || ''}
            </Link>
        </BreadcrumbsComponent>
    )
}

export default Breadcrumbs
