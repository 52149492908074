const OpportunitiesConfig = {
    columns: [
        { name: "projectName", title: "Project Name", width: 170, type: "text" },
        { name: "client", title: "Client", width: 170, type: "client" },
        { name: "enquiryDate", title: "Enquiry Date", width: 170, type: "date" },
        { name: "status", title: "Status", width: 170, type: "status" },
        { name: "referral", title: "Referral", width: 170, type: "client" },
        { name: "regiment", title: "Regiment", width:170, type: "client" },
        { name: "comments", title: "Comments", width:170, type:"text" }
    ]
}

export default OpportunitiesConfig