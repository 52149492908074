import ProfitLoss from "oldcomponents/ProfitLoss"

const Profit = () => {
    return (
        <div style={{margin: '20px'}}>
            Profit & Loss (Under construction)
        </div>
    )
}

export default ProfitLoss