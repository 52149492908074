import React, { useMemo, useState } from 'react'
import TaskAlt from '@mui/icons-material/TaskAlt'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { Spreadsheet } from '@framelink/spreadsheet_ts'
import renderCell from 'components/SpreadsheetCell'
import { Box } from '@mui/material'
import Breadcrumbs from 'components/Breadcrumbs'
import { AutoFixHigh } from '@mui/icons-material'
import EditForm from 'components/EditForm'
import { formConfig, FilterStatusItems, FilterTypeItems } from 'containers/TeamMembers/formConfig'
import Column from 'containers/TeamMembers/column'
import { Row } from './row'


const icon = {
    fontSize: 18
}

const TOPBAR_HEIGHT = 64
const PANEL_HEIGHT = 78

const TeamMembers = () => {
    const [openForm, setOpenForm] = useState<boolean>(false)
    const [checked, setCheck] = useState<number[]>([])
    const handleSelect = (rowIds: number[]) => setCheck(rowIds)
    const [openStatusFilter, setOpenStatusFilter] = useState<HTMLDivElement>()
    const [openTypeFilter, setOpenTypeFilter] = useState<HTMLDivElement>()
    const handleStatusFilterOpen = (e: React.MouseEvent<HTMLDivElement>) => setOpenStatusFilter(e.currentTarget)
    const handleTypefilterOpen = (e: React.MouseEvent<HTMLDivElement>) => setOpenTypeFilter(e.currentTarget)
    const handleStatusFilterClose = () => setOpenStatusFilter(undefined)
    const handleTypeFilterClose = () => setOpenTypeFilter(undefined)
    const filterStatusItems = (value: string) => handleStatusFilterClose()
    const filterTypeItems = (value: string) => handleTypeFilterClose()

    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

    const convertTo1Indexed = (rowId: number) => rowId - 1;

    const handleFormOpen = (rowIndex: number) => {
        if(rowIndex >= 0){
        const oneIndexedRowIndex = convertTo1Indexed(rowIndex);
        setSelectedRowIndex(oneIndexedRowIndex);
        setOpenForm(true);
        }
      };
  
    const handleFormClose = () => {
      setOpenForm(false)
    }
  
    const handleConfirm = () => {
      handleFormClose()
    }
    
    const selectedRow = selectedRowIndex !== null ? Row[selectedRowIndex] : null;

    const initialValues = useMemo(() => {
      if (selectedRow) {
        return {
          employee: selectedRow.employee || '',
          manager: selectedRow.manager || '',
          role: selectedRow.role || '',
          department: selectedRow.department || '',
          start_date: selectedRow.start_date || null,
          level: selectedRow.level || null,
        };
      }
      return {
        employee: '',
        manager: '',
        role: '',
        department: '',
        start_date: null,
        level: null,
      };
    }, [selectedRowIndex]);

    return (
        <React.Fragment>
            <Box style={{display: 'flex', height: TOPBAR_HEIGHT}}>
                <Breadcrumbs texts={['TeamMembers']} />
                    <Box style={{margin: '18px', marginLeft: 'auto'}}>
                        <Chip
                            icon={<TaskAlt sx={icon} />}
                            label={'Status'}
                            sx={{
                                cursor: 'pointer',
                                width: '150px'
                            }}
                            onClick={handleStatusFilterOpen}
                        />
                        <Chip 
                            icon={<AutoFixHigh sx={icon} />}
                            label={'Type'}
                            sx={{
                                cursor:'pointer',
                                width:'150px'
                            }}
                            onClick={handleTypefilterOpen}
                        />

                    <Menu
                        anchorEl={openStatusFilter}
                        open={!!openStatusFilter}
                        onClose={handleStatusFilterClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        slotProps={{
                            paper: {
                                sx: {
                                    marginTop: '10px',
                                    width: '150px'
                                }
                            }
                        }}
                    >
                        {Object.values(FilterStatusItems).map(item => (
                        <MenuItem onClick={() => filterStatusItems(item)}
                            key={item}
                            value={item}
                        >
                        {item}
                        </MenuItem>
                        ))}
         
                    </Menu>
                    <Menu
                        anchorEl={openTypeFilter}
                        open={!!openTypeFilter}
                        onClose={handleTypeFilterClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        slotProps={{
                            paper: {
                                sx: {
                                    marginTop: '10px',
                                    width: '150px'
                                }
                            }
                        }}
                    >
                        {Object.values(FilterTypeItems).map(item => (
                        <MenuItem onClick={() => filterTypeItems(item)}
                            key={item}
                            value={item}
                            >
                        {item}
                        </MenuItem>
                        ))}
                    </Menu>
                    
                    </Box>
            </Box>
            <Box style={{height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${PANEL_HEIGHT}px)`, display: 'flex'}}>
            <Spreadsheet
                order={[]}
                editableHeader={false}
                fixedCellWidth={100}
                checkedRows={checked}
                propId='id'
                rows={Row}
                columns={Column.columns}
                heightRow={50}
                onCheck={handleSelect}
                renderCell={renderCell}
                onRowClick={(rowId) => handleFormOpen(rowId)}
            />
            <EditForm
                open={openForm}
                handleClose={handleFormClose}
                handleConfirm={handleConfirm}
                title={'Change Team Member'}
                config={formConfig} 
                initialValues={initialValues}                      
            />
            </Box>
        </React.Fragment>
    )
}

export default TeamMembers