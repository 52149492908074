import React, {useRef, useMemo} from 'react'
import Form from 'components/EditForm/Form'
import {IComponent, Value} from 'components/EditForm/interfaces'

const EditForm = (props: IComponent) => {
    const key = useRef<number>(0)

    const currentKey = useMemo(() => {
        if (props.open) {
            key.current = key.current + 1
        }

        return key.current
    }, [props.open])
    
    return (
        <Form
            key={currentKey}
            open={props.open}
            handleClose={props.handleClose}
            handleConfirm={props.handleConfirm}
            title={props.title}
            description={props.description}
            config={props.config}
            getOptions={props.getOptions}
            getAutocomplete={props.getAutocomplete}
            initialValues={props.initialValues}
        />
    )
}

export default React.memo(EditForm)
