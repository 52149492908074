import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {ApiRoute} from 'api/enums'
import {IReqId} from 'api/interfaces'
import api from 'utils/api'

interface IProject {
    id: number,
    name: string,
    [prop: string]: any // I use 'any' here because I don't know exact type
}

interface ISession {
    [prop: string]: any // I use 'any' here because I don't know exact type
}

interface IState {
    projects: any, // todo - replace any
    projectItems: any, // todo - replace any
    projectSuppliers: any,
    projectStats: any | null, // todo - replace any
    projectInfo: any | null, // todo - replace any
    projectKpi: any | null, // todo - replace any
    pending: boolean
}

const initialState: IState = {
    projects: [],
    projectItems: [],
    projectSuppliers: [],
    projectStats: null,
    projectInfo: null,
    projectKpi: null,
    pending: false
}

export const getProjects = createAsyncThunk('projects/getProjects', async () => {
        const response = await api(ApiRoute.getprojects)
        return response.data
    }
)

export const getProjectInfo = createAsyncThunk('project/getProjectInfo', async (id: number) => (await api(ApiRoute.getprojectinfo, { id })).data)

export const getProjectItems = createAsyncThunk('project/getProjectItems', async (id: number) => (await api(ApiRoute.getprojectitems, { id })).data)

export const getProjectSuppliers = createAsyncThunk('project/getProjectSuppliers', async (id: number) => (await api(ApiRoute.getprojectsuppliers, { id })).data)

export const getProjectStats = createAsyncThunk('project/getProjectStats', async (id: number) => (await api(ApiRoute.getprojectstats, { id })).data)

export const getProjectKpi = createAsyncThunk('project/getProjectKpi', async (id: number) => (await api(ApiRoute.getprojectkpi, { id })).data)

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getProjects.pending, (state, action) => {
                state.pending = true
            })
            .addCase(getProjects.fulfilled, (state, action) => {
                state.projects = action.payload
                state.pending = false
            })
            .addCase(getProjects.rejected, (state, action) => {
                state.pending = false
            })
            .addCase(getProjectItems.fulfilled, (state, action) => {
                state.projectItems = action.payload;
                // todo - delete hotfix below
                const prop = 'item_id'
                state.projectItems.forEach((item: { [x: string]: any; id: any; suppliers: any[] }) => {
                    item.id = item[prop]
                    if (item.suppliers && item.suppliers.length) {
                        item.suppliers.forEach((supplier: any) => supplier.id = supplier[prop])
                    }
                })
            })
              
            .addCase(getProjectSuppliers.fulfilled, (state, action) => {
                state.projectSuppliers = action.payload
            })
            .addCase(getProjectInfo.fulfilled, (state, action) => {
                state.projectInfo = action.payload
            })
            .addCase(getProjectStats.fulfilled, (state, action) => {
                state.projectStats = action.payload
            })
            .addCase(getProjectKpi.fulfilled, (state, action) => {
                state.projectKpi = action.payload
            })
    },
})

export default projectSlice