const Column = {
  columns: [
{
  name: "employee",
  title: "Employee",
  width: 170,
  type: "text"
},
{
  name: "manager",
  title: "Manager",
  width: 170,
  type: "client"
},
{
  name: "role",
  title: "Role",
  width: 170,
  type: "client"
},
{
  name: "department",
  title: "Department",
  width: 170,
  type: "client"
},
{
  name: "start_date",
  title: "Start date",
  width: 170,
  type: "text"
},
{
  name: "level",
  title: "Level",
  width: 170,
  type: "text"
}
  ]
}

export default Column