import Card, { types } from 'components/Card';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import TaskAlt from '@mui/icons-material/TaskAlt';
import GroupIcon from '@mui/icons-material/Group';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AutoFixHigh from '@mui/icons-material/AutoFixHigh'
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import React, { useState, useMemo } from 'react';
import Breadcrumbs from 'components/Breadcrumbs'
import EditForm from 'components/EditForm';
import { formConfig } from 'enums/projects';
import { overview } from 'data/overview';
import  projects  from 'data/projects';


const icon = {
    fontSize: 18
}

const TOPBAR_HEIGHT = 64

const chip = {
    width: '180px',
    padding: '0 10px',

    '.MuiChip-icon': {
        color: 'inherit',
        fontSize: 18
    }
}


const config1 = {
    id: 'id',
    title: 'name',
    fields: [
        {
            label:'Project Type:',
            icon: <AutoFixHigh sx={icon}/>,
            type:types.Chip,
            value:'project_type',
            props: {
                id:'type_id',
                text:'project_type',
                background:'project_color',
            }
        },
        {
            label:'Attendees:',
            icon: <GroupIcon sx={icon}/>,
            type: types.Text,
            value:'attendees'
        },
        {
            label:'Date Enquiry Received',
            icon: <CalendarMonth sx={icon}/>,
            type:types.Date,
            value:'date_enquiry'
        },
        {
            label:'Date Response Req.:',
            icon:<CalendarMonth sx={icon}/>,
            type:types.Date,
            value:'date_response'
        },
        {
            label: 'Status',
            icon: <TaskAlt sx={icon} />,
            type: types.Chip,
            value: 'status_name',
            props: {
                id: 'status_id',
                text: 'status_name',
                background: 'status_color'
            }
        },
    ],
};
const config2 = {
    id: 'id',
    title: '',
    fields: [
        {
            label:'Pre-con/Build Start',
            icon:<CalendarMonth sx={icon}/>,
            type: types.Date,
            value:'date_build'
        },
        {
            label:'Site Inspection Start:',
            icon:<CalendarMonth sx={icon}/>,
            type: types.Date,
            value:'site_inspection_start'
        },
        {
            label: 'Project Date From:',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'starts'
        },
        {
            label:'Site Inspection End:',
            icon: <CalendarMonth sx={icon}/>,
            type:types.Date,
            value:'site_inspection_end'
        },
        {
            label: 'Project Date Until:',
            icon: <CalendarMonth sx={icon} />,
            type: types.Date,
            value: 'ends'
        },
        {
            label:'Location:',
            icon:<LocationOnIcon sx={icon}/>,
            type: types.Text,
            value:'location'
        },
        {
            label:'Client Departs/Dismantling complete:',
            icon:<CalendarMonth sx={icon}/>,
            type:types.Date,
            value:'client_departs'
        }
    ]
}

const Overview = () => {
  const project = overview[0];

  const [openForm, setOpenForm] = useState<boolean>(false)
  const [openPopup, setOpenPopup] = useState<boolean>(false)

  const handleFormOpen = () => {
      setOpenForm(true)
  }

  const handleFormClose = () => {
    setOpenForm(false)
}

const handleConfirm = () => {
    handleFormClose()
}

const handlePopupOpen = () => {
    setOpenPopup(true)
}

const handlePopupClose = () => {
    setOpenPopup(false)
}

const handleDelete = () => {
    handlePopupClose()
}

const initialValues = useMemo(() => {
    return {
        name: project ? project.name : '',
        starts: project ? project.starts : null,
        ends: project ? project.ends : null,
        attendees: project ? project.attendees:null,
        typeId: project ? project.project_type : null,
        statusId: project ? project.status_name : null,
        location: project ? project.location : null,

        //Not mapping correctly
        //teamMembers: projects ? projects.map((item: any) => item.teamMembers) : []

        
        /*
        * Not sure if these fields are necessary for this page *

            clientId: project ? project.clientId : null,
            contactId: project ? project.contactId : null,
            context: project ? project.context : '',

        */
    }
}, [project])

  return (
    <React.Fragment>
        <Box style ={{display: 'flex', height: TOPBAR_HEIGHT}}>
          <Breadcrumbs texts={['SolidWorks meeting in Lisbon', 'Overview']}/>
            <Box style={{margin:'18px', marginLeft: 'auto'}}>
            <Chip
                            icon={<TaskAlt sx={icon} />}
                            label={'Change Project'}
                            sx={{
                                cursor: 'pointer',
                                width: '150px'
                            }}
                            onClick={handleFormOpen}
                        />
            <Chip 
                            icon={<AutoFixHigh sx={icon} />}
                            label={'Delete Project'}
                            sx={{
                                cursor:'pointer',
                                width:'150px'
                            }}
                            onClick={handlePopupOpen}
                        />

                </Box>
            </Box>
                         {project ? (
       <div> 
        <Card config={config1} data={project} presentation />
        <Card config={config2} data={project} presentation />
        </div>
      ) : (
        <div>No project data available.</div>
      )}
 <EditForm
              open={openForm}
              handleClose={handleFormClose}
              handleConfirm={handleConfirm}
              title={'Change project'}
              config={formConfig}
              initialValues={initialValues}         
              />

<Dialog
                open={openPopup}
                onClose={handlePopupClose}
            >
                <DialogTitle>
                    Confirm your action
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the project {project && project.name}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePopupClose}>Cancel</Button>
                    <Button onClick={handleDelete} variant='contained'>Confirm</Button>
                </DialogActions>
            </Dialog>
       
    </React.Fragment>
  );
};

export default Overview;
