import axios from 'axios'
import { IApiInstance, INSTANCES_ENV as INSTANCES, PRIMARY_INSTANCE } from 'env'

const instance = INSTANCES.find((i: IApiInstance) => i.name === PRIMARY_INSTANCE)
const SERVER_URL = instance?.address || 'unknownserver'

axios.defaults.withCredentials = true

const api = <T, D>(path: string, data?: T) => {
    return axios({
        method: 'post',
        baseURL: SERVER_URL,
        url: path,
        data: data
    })
}

export default api