import { useEffect, useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import SpreadsheetProfitLoss from "./SpreadsheetProfitLoss";
import { useAppDispatch, useAppSelector } from 'app/hooks'
import React from "react";
import Breadcrumbs from "components/Breadcrumbs";
import PanelProjectDetails from "./PanelProjectDetails";
import { getProjectInfo, getProjectKpi, getProjectStats } from "slices/project";
import ModalSelectSupplier from "oldcomponents/ModalSelectSupplier";
import { useSearchParams } from "react-router-dom";

const typography = {
    letterSpacing: '0.5px',
    color: '#708085',
    fontSize: '14px'
}

const breadcrumbs = [
    <Link underline='none' key="1" color="inherit" href="/projects">
        Projects
    </Link>,
    <Typography key="2" color="text.primary" sx={typography}>
        Communication Techniques and Public Speaking
    </Typography>,
]

const ProfitLoss = (props: { projectId: number }) => {
    const dispatch = useAppDispatch()
    // const TOPBAR_HEIGHT = document.querySelector('header')?.clientHeight || 0
    const TOPBAR_HEIGHT = 64
    const PANEL_HEIGHT = 78
    const SIDEBAR_WIDTH = 0
    const projects = useAppSelector(state => state.project.projects)

    const [mode, setMode] = useState<number>(0)

    const info = useAppSelector(state => state.project.projectInfo)
    const stats = useAppSelector(state => state.project.projectStats)
    const kpi = useAppSelector(state => state.project.projectKpi)

    useEffect(() => {
        dispatch(getProjectInfo(props.projectId))
        dispatch(getProjectStats(props.projectId))
        dispatch(getProjectKpi(props.projectId))
    }, [])

    // const stats = {
    //     totalTO: 11850,
    //     totalCosts: 4000,
    //     gp: 7850
    // }

    // const info = projects ? projects[0] : {}

    // console.log(info)

    // const kpi = {
    //     convSpeed: '14,3 days',
    //     clientInteractions: 11
    // }

    return (
        <React.Fragment>
            <Box style={{ display: 'flex', height: `${PANEL_HEIGHT}px` }}>
                <Breadcrumbs texts={['SolidWorks meeting in Lisbon', 'Profit & Loss']} />
                <PanelProjectDetails panel="P&L" info={info || {}} stats={stats || {}} kpi={kpi || {}} />
            </Box>
            <Box style={{height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${PANEL_HEIGHT}px)`, display: 'flex'}}>
                <SpreadsheetProfitLoss projectId={props.projectId} />
            </Box>
        </React.Fragment>
    )
}

export default ProfitLoss