import React from 'react'
import styled from '@emotion/styled'
import Text from 'components/EditForm/Inputs/Text'
import Date from 'components/EditForm/Inputs/Date'
import {types} from 'enums/fields'
import {Option, Value} from 'components/EditForm/interfaces'

const Container = styled.div`
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
    width: 100%;
    display: inline-flex;
`

interface IComponent {
    value: Value,
    name: string,
    title: string,
    type: string,
    min?: Value,
    max?: Value,
    onChange: (name: string, value: Value) => void,
    options: Option[],
    disabled: boolean
}

const FormField = (props: IComponent) => {
    const getField = () => {
        switch (props.type) {
            case types.Date:
                return (
                    <Date
                        value={props.value as string}
                        name={props.name}
                        onChange={props.onChange}
                        title={props.title}
                        datetime={false}
                        min={props.min as string}
                        max={props.max as string}
                        disabled={props.disabled}
                    />
                )

            case types.DateTime:
                return (
                    <Date
                        value={props.value as string}
                        name={props.name}
                        onChange={props.onChange}
                        title={props.title}
                        datetime={true}
                        min={props.min as string}
                        max={props.max as string}
                        disabled={props.disabled}
                    />
                )

            default:
                return (
                    <Text
                        value={props.value as string}
                        name={props.name}
                        onChange={props.onChange}
                        title={props.title}
                        multiline={false}
                        disabled={props.disabled}
                    />
                )
        }
    }

    return (
        <Container>
            {getField()}
        </Container>
    )
}

export default React.memo(FormField)
