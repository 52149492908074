export interface IApiInstance {
    name: string
    address: string
}

const env = <T>(key: string, def: T): T => {
    const value: string | undefined = (window as any)._env_![key];
    if (value) {
        if (def instanceof Array) {
            if (typeof def[0] === 'string') {
                return value.split(',') as any as T;
            } else {
                try {
                    return JSON.parse(value);
                } catch {
                    console.log(`.env parser error: ${key} = ${value}`)
                    return [] as any as T;
                }
            }
        } else if (typeof def === 'string') {
            return value as any as T;
        } else if (typeof def === 'number') {
            const num: number = Number(value);
            if (!isNaN(num)) {
                return Number(value) as any as T;
            }
        }
    }
    return def;
}

const parseInstances = (config: string): IApiInstance[] => {
    const instances: IApiInstance[] = [];
    config.split(';').forEach((inst: string) => {
        const split: string[] = inst.split('=');
        if (split[0] && split[1]) {
            instances.push({ name: split[0], address: split[1] });
        }
    });
    return instances;
}

export const PRIMARY_INSTANCE: string = env<string>('PRIMARY_INSTANCE', '');
export const INSTANCES_ENV: IApiInstance[] = parseInstances(env<string>('INSTANCES', ''));
export const LOCAL_STORAGE_ITEM_NAME: string = env<string>('LOCAL_STORAGE_ITEM_NAME', 'appdata');
export const ROUTER_BASENAME: string = env<string>('ROUTER_BASENAME', '');