import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import styled from '@emotion/styled'
import Paper from '@mui/material/Paper'
import Chip from '@mui/material/Chip'
import dayjs from 'dayjs'
import {useTheme} from '@mui/material/styles'

export const types = {
    Text: 'text',
    Date: 'date',
    Chip: 'chip',
    Chips: 'chips'
}

const Name = styled.div<{presentation?: boolean}>`
    margin-bottom: 20px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${props => props.presentation && `
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: normal;
    `}
`

const Item = styled.div<{presentation?: boolean}>`
    font-size: 13px;
    margin-bottom: 20px;
    padding-right: ${props => props.presentation ? '100px' : '0'};
`

const Title = styled.div<{textColor: string}>`
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    color: ${props => props.textColor};
    white-space: nowrap;
`

const Icon = styled.div`
    margin-right: 5px;
`

const Text = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
`

const Note = styled.div<{presentation?: boolean}>`
    ${props => props.presentation && `
        line-height: 20px;
        font-size: 14px;
        letter-spacing: 0.5px;
    `}
`

const paper = {
    padding: '20px',
    cursor: 'pointer',
    height: '100%',
    width: '100%'
}

const presentation = {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
    background: '#f5f5f5'
}

const chip = {
    marginBottom: '10px',
    marginRight: '10px'
}

interface IField {
    label: string,
    icon: JSX.Element,
    type: string,
    value: string,
    props?: {
        id: string,
        text: string,
        background: string
    }
}

interface IComponent {
    onClick?: (id: number) => void,
    data: any,
    presentation?: boolean,
    config: {
        id: string,
        title: string,
        fields: IField[]
    }
}

const Card = (props: IComponent) => {
    const theme = useTheme()

    const getItem = (field: IField) => {
        const value = props.data[field.value]

        switch (field.type) {
            case types.Date:
                return (
                    <Note presentation={props.presentation}>
                        {value ? dayjs(value).format('DD MMM YYYY') : ''}
                    </Note>
                )

            case types.Chip:
                return (
                    <div>
                        <Chip label={value}
                            sx={{
                                background: field.props && field.props.background ? props.data[field.props.background] : ''
                            }}
                        />
                    </div>
                )

            case types.Chips:
                return (
                    <div>
                        {field.props && value.map((item: {[key: string]: string}) => {
                            if (field.props) {
                                return  <Chip
                                    key={item[field.props.id]}
                                    label={item[field.props.text]}
                                    sx={{
                                        ...chip,
                                        background: field.props.background ? item[field.props.background] : ''
                                    }}
                                />
                            }
                        })}
                    </div>
                )

            default:
                return (
                    <Note presentation={props.presentation}>{value}</Note>
                )
        }

    }

    const onClick = () => {
        if (typeof props.onClick === 'function') {
            props.onClick(props.data[props.config.id])
        }
    }

    return (
        <Paper onClick={onClick} sx={props.presentation ? {...paper, ...presentation} : {...paper}}>
            <Name presentation={props.presentation}>{props.data[props.config.title]}</Name>
            {props.config.fields.map(field => {
                const value = props.data[field.value]

                if (!value || !value.length) return
                
                return (
                    <Item key={field.value} presentation={props.presentation}>
                        <Title textColor={theme.palette.info.dark}>
                            {field.icon &&
                                <Icon>{field.icon}</Icon>
                            }
                            <Text>{field.label}</Text>
                        </Title>
                        {getItem(field)}
                    </Item>
                )
            })}
        </Paper>
    )
}

export default Card