export const OpportunitiesFilters = ['Client', 'Referral', 'Month', 'Regiment']

export const OpportunitiesStatus = ['To Qualify', 'Unqualified']

// { name: 'To Qualify', color: '#f48fb1', protected: true },
// { name: 'Accepted', color: '#81d4fa', protected: true },
// { name: 'In progress', color: '#ffe082', protected: true },
// { name: 'Complete', color: '#a5d6a7', protected: true },
// { name: 'Unqualified', color: '#9fa8da', protected: true }

export const OpportunitiesData = [
    {
        "id": 1,
        "projectName": "SolidWorks meeting in Lisbon ",
        "client": "Switch Event Marketing",
        "enquiryDate":"2024-04-03T12:11:00.000Z",
        "status": "To Qualify",
        "status_color": "#f48fb1",
        "protected": true,
        "referral": "John Doe",
        "regiment": "Travel Agency",
        "comments": ""
    },
    {
        "id": 2,
        "projectName": "MCAK",
        "client": "MCAK",
        "enquiryDate":"2024-06-12T12:14:00.000Z",
        "status": "Unqualified",
        "status_color":"#9fa8da",
        "protected": true,
        "referral": "Jane Doe",
        "regiment": "Travel Agency",
        "comments": ""
    },
    {
        "id": 3,
        "projectName": "LGUF",
        "client": "LGUF",
        "enquiryDate":"2024-03-08T12:09:00.000Z",
        "status": "To Qualify",
        "status_color": "#f48fb1",
        "protected": true,
        "referral": "John Doe",
        "regiment": "Management Fee",
        "comments": ""
    },
    {
        "id": 4,
        "projectName": "NEUK",
        "client": "MTAI",
        "enquiryDate":"2024-11-10T12:14:00.000Z",
        "status": "To Qualify",
        "status_color": "#f48fb1",
        "protected": true,
        "referral": "John Doe",
        "regiment": "Travel Agency",
        "comments": "Something, something"
    },
    {
        "id": 5,
        "projectName": "IHHV",
        "client": "HLFW",
        "enquiryDate":"2024-02-01T12:18:00.000Z",
        "status": "To Qualify",
        "status_color": "#f48fb1",
        "protected": true,
        "referral": "Jane Doe",
        "regiment": "Travel Agency",
        "comments": ""
    },
    {
        "id": 6,
        "projectName": "SolidWorks meeting in Lisbon ",
        "client": "SolidWorks",
        "enquiryDate":"2024-04-03T12:11:00.000Z",
        "status": "Unqualified",
        "status_color":"#9fa8da",
        "protected": true,
        "referral": "John Doe",
        "regiment": "Management Fee",
        "comments": ""
    },
    {
        "id": 7,
        "projectName": "MCAK",
        "client": "MCAK",
        "enquiryDate":"2024-06-12T12:14:00.000Z",
        "status": "To Qualify",
        "status_color":"#f48fb1",
        "protected": true,
        "referral": "Jane Doe",
        "regiment": "Travel Agency",
        "comments": ""
    },
    {
        "id": 8,
        "projectName": "LGUF",
        "client": "LGUF",
        "enquiryDate":"2024-03-08T12:09:00.000Z",
        "status": "To Qualify",
        "status_color": "#f48fb1",
        "protected": true,
        "referral": "John Doe",
        "regiment": "Travel Agency",
        "comments": ""
    },
    {
        "id": 9,
        "projectName": "NEUK",
        "client": "MTAI",
        "enquiryDate":"2024-11-10T12:14:00.000Z",
        "status": "Unqualified",
        "status_color":"#9fa8da",
        "protected": true,
        "referral": "John Doe",
        "regiment": "Management Fee",
        "comments": "Something, something"
    },
    {
        "id": 10,
        "projectName": "IHHV",
        "client": "HLFW",
        "enquiryDate":"2024-02-01T12:18:00.000Z",
        "status": "Unqualified",
        "status_color":"#9fa8da",
        "protected": true,
        "referral": "Jane Doe",
        "regiment": "Travel Agency",
        "comments": ""
    },
    {
        "id": 11,
        "projectName": "SolidWorks meeting in Lisbon ",
        "client": "SolidWorks",
        "enquiryDate":"2024-04-03T12:11:00.000Z",
        "status": "To Qualify",
        "status_color": "#f48fb1",
        "protected": true,
        "referral": "John Doe",
        "regiment": "Travel Agency",
        "comments": ""
    },
    {
        "id": 12,
        "projectName": "MCAK",
        "client": "MCAK",
        "enquiryDate":"2024-06-12T12:14:00.000Z",
        "status": "Unqualified",
        "status_color":"#9fa8da",
        "protected": true,
        "referral": "Jane Doe",
        "regiment": "Management Fee",
        "comments": ""
    },
    {
        "id": 13,
        "projectName": "LGUF",
        "client": "To Qualify",
        "enquiryDate":"2024-03-08T12:09:00.000Z",
        "status": "Unqualified",
        "status_color":"#9fa8da",
        "protected": true,
        "referral": "John Doe",
        "regiment": "Travel Agency",
        "comments": ""
    },
    {
        "id": 14,
        "projectName": "NEUK",
        "client": "MTAI",
        "enquiryDate":"2024-11-10T12:14:00.000Z",
        "status": "To Qualify",
        "status_color": "#f48fb1",
        "protected": true,
        "referral": "John Doe",
        "regiment": "Travel Agency",
        "comments": "Something, something"
    },
    {
        "id": 15,
        "projectName": "IHHV",
        "client": "To Qualify",
        "enquiryDate":"2024-02-01T12:18:00.000Z",
        "status": "Unqualified",
        "status_color":"#9fa8da",
        "protected": true,
        "referral": "Jane Doe",
        "regiment": "Management Fee",
        "comments": ""
    },
    
]