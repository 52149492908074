import {types} from 'enums/fields'

export const formConfig = [
    {
        title: 'Name',
        type: types.Text,
        name: 'teamMember',
        required: true
    },
    {
        title: 'Manager',
        type: types.Text,
        name: 'manager',
        required: false
    },
    {
        title: 'Role',
        type: types.Chip,
        name: 'role',
        required: true
    },
    {
        title: 'Department',
        type: types.Chip,
        name: 'department',
        required: false
    },
]

export const formConfigEvaluation = [
    {
        title: 'Culture',
        type: types.Text,
        name: 'culture',
        required: true
    },
    {
        title: 'Adaptability',
        type: types.Text,
        name: 'adaptability',
        required: true
    },
    {
        title: 'Resilience',
        type: types.Chip,
        name: 'resilience',
        required: true
    },
    {
        title: 'Empathy',
        type: types.Chip,
        name: 'empathy',
        required: true
    },
    {
        title: 'Ownership',
        type: types.Chip,
        name: 'ownership',
        required: true
    },
    {
        title: 'Collaborative',
        type: types.Chip,
        name: 'colaborative',
        required: true
    },
    {
        title: 'Team Management',
        type: types.Chip,
        name: 'team_management',
        required: true
    },
    {
        title: 'Leadership',
        type: types.Chip,
        name: 'leadership',
        required: true
    },
    {
        title: 'VOQUIN\'s Global Vision Understanding',
        type: types.Chip,
        name: 'gvu',
        required: true
    },
]