import TeamMembers from "containers/TeamMembers/overview";

export default {
    Signin: '/signin',
    Projects: '/projects',
    Opportunities: '/opportunities',
    Suppliers: '/suppliers',
    Members: '/members',
    Member: '/member',
    
    VoqinWay: '/voqinway',
    Procurement: '/procurement',
    Project: '/project',

    Program: 'program',
    Overview: 'overview',
    Profit: 'profit',
    ProjectHistory: 'projecthistory',
    ProjectGhant: 'projectghant',
    SiteInspections: 'site_inspections',
    RiskAssessment: 'risk_assessment',
    Invoices: 'invoices',
    Balance: 'balance',
    Actions: 'actions',

    TeamMembersOverview:'member_overview',
    PerformanceEvaluation:'member_evaluation'
}
