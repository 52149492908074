export const MockFirstNameFemale: string[] = [
    'Agatha', 'Alberta', 'Alice', 'Anna', 'April', 'Ashley',
    'Barbara', 'Bertha', 'Bonnie', 'Bridget', 'Caren', 'Carlyne', 'Cheryl', 'Courtney', 'Emma',
    'Dolores', 'Deborah', 'Edna', 'Elisabeth', 'Eva', 'Jennifer', 'Jessica', 'Judith', 'Norah',
    'Gabrielle', 'Gloria', 'Helen', 'Isabelle', 'Jane', 'Janet', 'Julia', 'June', 'Kristina',
    'Laura', 'Lillian', 'Linda', 'Lisa', 'Louisa', 'Margaret', 'Martha', 'Melanie', 'Michelle',
    'Miranda', 'Monica', 'Natalie', 'Nina', 'Olivia', 'Patricia', 'Rachel', 'Rosa'
];

export const MockFirstNameMale: string[] = [
    'Donald', 'Jared', 'Bernard', 'Bradley', 'Victor', 'Bruce', 'Bruno', 'Calvin', 'Ivan',
    'Chester', 'Clayton', 'Clyde', 'Cornelius', 'Delbert', 'Dexter', 'Edgar', 'Colin', 'Oscar',
    'Michael', 'Steven', 'David', 'Jordan', 'Mathew', 'Charles', 'Duncan', 'Anthony', 'Oliver',
    'John', 'Carlos', 'Ashton', 'Persival', 'Adam', 'Adrian', 'Alan', 'Jack', 'Dean', 'Fabian',
    'Fredrick', 'George', 'Robert', 'Andreas', 'Peter', 'James', 'Stewart', 'Miguel', 'Newton',
    'Lloyd', 'Leroy', 'Chris', 'Rick'
];

export const MockLastName: string[] = [
    'Smith', 'Jones', 'Williams', 'Taylor', 'Davies', 'Milner', 'Johnson', 'Roberts', 'Morgan',
    'Walker', 'Wright', 'Robinson', 'Thompson', 'White', 'Hughes', 'Edwards', 'Green', 'Lewis',
    'Simpson', 'Richardson', 'Bailey', 'Cox', 'Adams', 'Wilkinson', 'Russell', 'Mason', 'Webb',
    'Wood', 'Harris', 'Martin', 'Jackson', 'Clarke', 'Henderson', 'Kent', 'Griffiths', 'Young',
    'Norton', 'Oakley', 'Pinkerton', 'Ramsey', 'Easton', 'Darby', 'Lee', 'Lindsey', 'Gunn',
    'Allen', 'Mitchell', 'Phillips', 'Campbell', 'Kelly', 'Parker', 'Miller', 'Noble', 'Evans',
    'Reid', 'Barnes', 'Harvey', 'Barker', 'Maxwell', 'Kinsley', 'Drummond', 'Merton', 'Thomas',
    'Douglas', 'Dunnet', 'Abercrombie', 'Flanagan', 'Gibson', 'Dunn', 'Wallace', 'Powell',
    'Fox', 'Ward', 'Gray', 'Sanders', 'Coleman', 'Powell', 'Butler', 'Owens', 'Myers', 'Baker',
    'Murray', 'Tucker', 'Crawford', 'Berry', 'Gordon', 'Shaw', 'Daniels', 'Palmer', 'Ferguson',
    'Hawkins', 'Simmons', 'Spencer', 'Stephens', 'Matthews', 'Robertson', 'Arnold', 'Franklin',
    'Harper', 'Greene', 'Cunningham'
];

export const MockFirstNameAll: string[] = [...MockFirstNameMale, ...MockFirstNameFemale];