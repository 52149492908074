import React from 'react'
import {Outlet, Navigate} from 'react-router-dom'
import styled from '@emotion/styled'
import {useAppDispatch, useAppSelector} from 'app/hooks'
import routes from 'enums/routes'

const Auth = () => {
    const authorized = useAppSelector(state => state.auth.authorized)
    const initialized = useAppSelector(state => state.auth.initialized)

    return (
        <React.Fragment>
            {!initialized || authorized ?
                <Outlet /> :
                <Navigate to={`${routes.Signin}`} replace={true} />
            }
        </React.Fragment>
    )
}

export default Auth