export const VoquinWayData = [
    {
        "name":"Voquin Way",
        "culture":"4",
        "adaptability":"3",
        "resilience":"3",
        "empathy":"4",
        "ownership":"2", 
        "collaborative":"3", 
        "team_management":"4",
        "leadership":"4",
        "gvu":"3",
        "peers": "3,96",
        "directReports":"3,67",
        "overall":"3,815",
        "self_rating":"03-01-2022",
    },
]