import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {ApiRoute} from 'api/enums'
import api from 'utils/api'

interface IState {
    sheetProjects: any, // todo - replace any
    sheetProjectItems: any, // todo - replace any
    sheetProjectSuppliers: any,  // todo - replace any
    sheetAllSuppliers: any,  // todo - replace any
    pending: boolean
}

const initialState: IState = {
    sheetProjects: null,
    sheetProjectItems: null,
    sheetProjectSuppliers: null,
    sheetAllSuppliers: null,
    pending: false
}

export const getSheetProjects = createAsyncThunk('ui/getSheetProjects', async () => (await api(ApiRoute.getspreadsheet, { name: 'projects' })).data)

export const getSheetProjectItems = createAsyncThunk('ui/getSheetProjectItems', async () => (await api(ApiRoute.getspreadsheet, { name: 'project_items' })).data)

export const getSheetProjectSuppliers = createAsyncThunk('ui/getSheetProjectSuppliers', async () => (await api(ApiRoute.getspreadsheet, { name: 'project_suppliers' })).data)

export const getSheetAllSuppliers = createAsyncThunk('ui/getSheetAllSuppliers', async () => (await api(ApiRoute.getspreadsheet, { name: 'all_suppliers' })).data)

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getSheetProjects.fulfilled, (state, action) => {
                state.sheetProjects = action.payload
            })
            .addCase(getSheetProjectItems.fulfilled, (state, action) => {
                state.sheetProjectItems = action.payload
            })
            .addCase(getSheetProjectSuppliers.fulfilled, (state, action) => {
                state.sheetProjectSuppliers = action.payload
            })
            .addCase(getSheetAllSuppliers.fulfilled, (state, action) => {
                state.sheetAllSuppliers = action.payload
            })
    },
})

export default uiSlice