import {createSlice} from '@reduxjs/toolkit'
import {getLogin} from 'slices/auth' 
import messages from 'enums/messages'

interface IMessage {
    text: string,
    variant: 'error' | 'info' | 'success' | 'warning',
    open: boolean
}

const initialState: IMessage = {
    text: messages.RegularError,
    variant: 'error',
    open: false
}

const messageSlice = createSlice({
    name: 'message',
    initialState: initialState,
    reducers: {
        messageReset: state => {
            state.open = false
        },
        messageSet: (state, action) => {
            state.text = action.payload.text
            state.variant = action.payload.variant
            state.open = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLogin.fulfilled, (state, action) => {
                if (!action.payload.user) {
                    state.text = messages.WrongCredentials
                    state.variant = 'error'
                    state.open = true
                }
            })
            .addCase(getLogin.rejected, (state) => {
                state.text = messages.LoginError
                state.variant = 'error'
                state.open = true
            })
    },
})

export const {messageReset, messageSet} = messageSlice.actions

export default messageSlice
