export const overview = [
    {
        "id": 1,
        "name": "SolidWorks meeting In Lisbon",
        "project_type": "Incentive Travel",
        "project_id":1,
        "project_color": "#7bc2ec",
        "date_enquiry":"2023-04-17T12:00:00.000Z",
        "attendees":"130",
        "date_response":"2023-04-25T12:00:00.000Z",
        "status_id": 2,
        "status_name": "Planning",
        "status_color": "#f48fb1",
        "status_protected": true,
        "date_build":"2023-04-02T12:11:00.000Z",
        "site_inspection_start": "",
        "starts": "2023-05-22T12:11:00.000Z",
        "site_inspection_end":"",
        "ends": "2023-05-25T12:11:00.000Z",
        "location":"Portugal",
        "client_departs": "2023-05-26T12:11:00.000Z",
    }
]